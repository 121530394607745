// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/icons/close.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary: #3d65fd;
  --primary--glimpse: #f3f5ff;
  --primary--touch: #dbe2ff;
  --error: #d90b0b;
  --txt-primary: #1a1f36;
  --txt-secondary: #3c4257;
  --txt-secondary--glimpse: #b5b7bf;
  --bg-primary: #ffffff;
  --bg-field: #f7f7f7;
  --bg-border: #e6e6e6;
  --bg-border-light: #d8d9dd;
  --bg-border-primary: #c3cffe;
  --bg-border-ok: #1bc436;
  --bg-border-error: #d2bcb4;
  --txt-ok: #0e6245;
  --txt-error: #a41c4e;
  --txt-error-contrast: #a41c4e;
  --txt-await: #3d4eac;
  --bg-alert: #ffebb2;
  --bg-info: #eceef7;
  --bg-ok: #cbf4c9;
  --bg-ok-light: #e2f6e1;
  --bg-await: #daebfd;
  --bg-error: #f9e3de;
  --border-topup-completed: #cbf4c9;
  --bg-topup-completed: #99d68c;
  --border-topup-pending: #daebfd;
  --bg-topup-pending: #8caed6;
  --border-topup-failed: #f9e3de;
  --bg-topup-failed: #d6958c;
  --fast-stat-border: yellowgreen;
}

:root.dark-theme {
  --primary: #bac3ff;
  --primary--glimpse: #2b2c35;
  --primary--touch: #505365;
  --error: #d90b0b;
  --txt-primary: #e4e1e6;
  --txt-secondary: #e4e1e6;
  --txt-secondary--glimpse: #b5b7bf;
  --bg-primary: #1b1b1f;
  --bg-field: #23232b;
  --bg-border: #46464e;
  --bg-border-light: #46464e;
  --bg-border-primary: #c3cffe;
  --bg-border-ok: #1bc436;
  --bg-border-error: #4a0003;
  --txt-ok: #7df8d7;
  --txt-error: #ffdad4;
  --txt-error-contrast: #ff8773;
  --txt-await: #cbe6ff;
  --bg-alert: #574500;
  --bg-info: #2a2f42;
  --bg-ok: #005140;
  --bg-ok-light: #004435;
  --bg-await: #004b73;
  --bg-error: #680003;
  --border-topup-completed: #205041;
  --bg-topup-completed: #0d3f2f;
  --border-topup-pending: #1d4a70;
  --bg-topup-pending: #0e3251;
  --border-topup-failed: #5f0f0b;
  --bg-topup-failed: #430b08;
  --fast-stat-border: yellow;
}

.base-modal-overlay {
  background: rgba(60, 66, 87, 0.4);
}

[data-class=base-modal-wrapper] {
  display: flex;
  justify-content: center;
  align-items: center;
}
[data-class=base-modal-wrapper][data-model-position=right] {
  justify-content: end;
}

.base-modal {
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-sizing: border-box;
  border: 1px solid var(--bg-border);
  background-color: var(--bg-primary);
  border-radius: 8px;
  padding: 24px;
  align-items: flex-start;
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  color: var(--txt-primary);
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}
.base-modal__cross::before {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center no-repeat;
}
.base-modal__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.base-modal__title--default {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.base-modal__footer {
  display: flex;
  gap: 16px;
  justify-content: right;
}
.base-modal__content {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  width: 100%;
  border-top: 1px solid var(--bg-border);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
