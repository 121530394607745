import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a51b5fd6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "post-btn" }
const _hoisted_2 = {
  key: 0,
  class: "post-btn__badge"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "header-button header-button--posts-list",
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('click')))
    }),
    ($setup.props.showBadge)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("v-if", true)
  ]))
}