export default (id: string, src: string): Promise<void | Event> =>
	new Promise<void | Event>((resolve, reject) => {
		const scriptElement = document.getElementsByTagName('script')[0];

		if (document.getElementById(id)) {
			return resolve();
		}

		const newScriptElement = document.createElement('script');
		newScriptElement.id = id;
		newScriptElement.src = src;
		newScriptElement.async = true;
		newScriptElement.onerror = reject;
		newScriptElement.onload = resolve;

		scriptElement.parentNode?.insertBefore(newScriptElement, scriptElement);
	});
