export const cardSharedProps = {
	modelValue: {
		type: Boolean,
		default: true,
	},
	collapsed: {
		type: Boolean,
		default: false,
	},
	title: {
		type: String,
	},
	tooltipText: {
		type: String,
	},
	isFirstInOrder: {
		type: Boolean,
		default: false,
	},
};

export type CardSharedPropKeys = Array<keyof typeof cardSharedProps>;
export const cardSharedPropKeys = Object.keys(cardSharedProps) as CardSharedPropKeys;
