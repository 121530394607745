export const formatsMap = new Map([
	['push', { id: 1, title: 'Push' }],
	['onClick', { id: 2, title: 'OnClick' }],
	['inPage', { id: 3, title: 'IPP' }],
	['native', { id: 4, title: 'Native' }],
	['calendar', { id: 5, title: 'Calendar' }],
]);

export enum featurePermissionEnum {
	PRESETS_ADM_VIEW = 'adm_presets',
	PRESETS_ADM_CREATE = 'adm_presets_create',
	PRESETS_ADM_EDIT = 'adm_presets_edit',
	PRESETS_PUBLIC_CREATE = 'adm_presets_public_create',
}

export enum PresetType {
	PUBLIC = 'public',
	PRIVATE = 'private',
}
