import { useRoute, useRouter } from 'vue-router';
import { onMounted } from 'vue';
import type { CookieAttributes } from 'js-cookie';
import { COOKIE_DOMAIN, REF_QUERY_KEY, REF_QUERY_SUBID1_KEY, REF_QUERY_SUBID2_KEY } from '@/helpers/const';
import cookiesService from '@/services/local/cookiesService';
import UserService from '@/services/local/UserService';

const COOKIE_ATTRIBUTES: CookieAttributes = {
	expires: 7,
	path: '/',
	domain: COOKIE_DOMAIN,
	secure: true,
	sameSite: 'strict',
};

export default () => {
	const route = useRoute();
	const router = useRouter();

	const getTokenFromQuery = async (): Promise<
		{ subid1: string | null; subid2: string | null; token: string | null } | undefined
	> => {
		await router.isReady();
		if (Object.prototype.hasOwnProperty.call(route.query, REF_QUERY_KEY)) {
			const {
				[REF_QUERY_KEY]: qr_token,
				[REF_QUERY_SUBID1_KEY]: qr_subid1,
				[REF_QUERY_SUBID2_KEY]: qr_subid2,
				...__query
			} = route.query;
			await router.replace({ query: __query });

			return {
				subid1: Array.isArray(qr_subid1) ? qr_subid1[0] : qr_subid1,
				subid2: Array.isArray(qr_subid2) ? qr_subid2[0] : qr_subid2,
				token: Array.isArray(qr_token) ? qr_token[0] : qr_token,
			};
		}
	};

	onMounted(async () => {
		const ref = await getTokenFromQuery();
		if (ref && !UserService.isLoggedIn()) {
			for (const k of Object.keys(ref)) {
				cookiesService.set(`ref_${k}`, ref[k], COOKIE_ATTRIBUTES);
			}
		}
	});
};
