<template>
	<social v-if="isShowSocial" />
</template>

<script lang="ts">
	import { useRoute } from 'vue-router';
	import { ref, watch } from 'vue';
	import Social from '@/components/Social.vue';

	export default {
		name: 'RaFooter',
		components: {
			Social,
		},
		setup() {
			const route = useRoute();
			const isShowSocial = ref(true);
			watch(
				() => route.fullPath,
				(newValue) => {
					isShowSocial.value = !newValue.includes('creatives');
				}
			);

			return {
				isShowSocial,
			};
		},
	};
</script>

<style lang="scss"></style>
