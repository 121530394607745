import { createRouter, createWebHistory, LocationQuery } from 'vue-router';
import qs from 'qs';
import { authGuard } from '@/router/middleware';
import { routes } from '@/router/routes';
import UserService from '../services/local/UserService';

export default async () => {
	await UserService.loadCurrentUser();

	const router = createRouter({
		history: createWebHistory(__dirname),
		parseQuery: (query) => qs.parse(query) as LocationQuery,
		// Установить encode: false если не хотим экранировать '[]'
		stringifyQuery: (query) => qs.stringify(query, { encode: true }),
		routes,
	});

	router.beforeEach(authGuard);

	return router;
};
