// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social {
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
  gap: 22px;
  padding-bottom: 32px;
}
.social__link {
  display: flex;
  align-items: center;
  justify-content: center;
}
.social svg {
  width: 18px;
  height: 18px;
}
.social svg path {
  fill: var(--txt-secondary);
}
.social svg:hover path {
  fill: var(--primary);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
