<template>
	<form class="post-create">
		<card title="Content">
			<div class="card__two-columns">
				<validation-field
					v-slot="{ field, errorMessage, meta }"
					:label="fromFields.post_type.spec.label"
					name="post_type"
				>
					<ra-select
						v-bind="field"
						:name="field.name"
						:error="errorMessage"
						:text="meta.label"
						:options="postTypes"
						:readonly="props.initialIsLoading"
						class="card__field"
						required
					/>
				</validation-field>
				<validation-field
					v-slot="{ field, errorMessage, meta }"
					:label="fromFields.post_status.spec.label"
					name="post_status"
				>
					<ra-select
						v-bind="field"
						:name="field.name"
						:error="errorMessage"
						:text="meta.label"
						:options="postStatuses"
						:readonly="props.initialIsLoading"
						class="card__field"
						required
					/>
				</validation-field>
				<validation-field
					v-slot="{ field, errorMessage, meta }"
					:label="fromFields.post_start_date.spec.label"
					name="post_start_date"
				>
					<date-picker
						v-bind="field"
						:name="field.name"
						:error="errorMessage"
						:text="meta.label"
						:min-date="formStartMinDate"
						:max-date="formStartMaxDate"
						:disabled="props.initialIsLoading"
						class="card__field"
						add-time
					/>
				</validation-field>
				<validation-field
					v-slot="{ field, errorMessage, meta }"
					:label="fromFields.post_end_date.spec.label"
					name="post_end_date"
				>
					<date-picker
						v-bind="field"
						:name="field.name"
						:error="errorMessage"
						:text="meta.label"
						:min-date="formEndMinDate"
						:max-date="formEndMaxDate"
						:disabled="props.initialIsLoading"
						class="card__field"
						add-time
					/>
				</validation-field>
			</div>
			<div class="card__one-column">
				<validation-field
					v-slot="{ field, errorMessage, meta }"
					:label="fromFields.post_title.spec.label"
					name="post_title"
				>
					<ra-input
						v-bind="field"
						:name="field.name"
						:error="errorMessage"
						:text="meta.label"
						:readonly="props.initialIsLoading"
						class="card__field"
						required
					/>
				</validation-field>
			</div>
			<div class="card__one-column">
				<validation-field
					v-slot="{ field, errorMessage, meta }"
					:label="fromFields.post_body.spec.label"
					name="post_body"
				>
					<ra-textarea
						v-bind="field"
						:name="field.name"
						:error="errorMessage"
						:text="meta.label"
						:disabled="props.initialIsLoading"
						class="card__field"
						required
					/>
				</validation-field>
			</div>
			<div class="card__three-columns" />
		</card>

		<card title="Preview" :model-value="false" class="post-create__preview" collapsed>
			<post v-bind="values" :post-id="0" :post-is-viewed="0" />
		</card>

		<card title="Actions">
			<button-main
				:loading="isSubmitting || props.initialIsLoading"
				:disabled="!isValid || !formMeta.dirty || isSubmitting || props.initialIsLoading || !!submitCount"
				class="card__cta"
				text="Save"
				img-class="save"
				@click="onSubmitHandler"
			/>
		</card>
	</form>
</template>
<script setup lang="ts">
	import { markRaw, toRef } from 'vue';
	import { object } from 'yup';
	import { Field as ValidationField, useForm, useIsFormValid, useIsSubmitting } from 'vee-validate';
	import moment from 'moment';

	import type { ICreatePostForm } from './types';
	import { Card } from '@/components/Card';
	import RaTextarea from '@/components/RaTextarea.vue';
	import DatePicker from '@/components/DatePicker.vue';
	import RaInput from '@/components/RaInput.vue';
	import RaSelect from '@/components/RaSelect.vue';
	import ButtonMain from '@/components/ButtonMain.vue';

	import Post from './Post.vue';
	import { fromFields, postTypes, postStatuses } from './shared';

	const props = withDefaults(
		defineProps<{
			initialValues?: Partial<ICreatePostForm>;
			submitHandler: (data: ICreatePostForm) => Promise<void>;
			initialIsLoading?: boolean;
		}>(),
		{
			initialValues: () => ({}),
		}
	);

	const validationSchema = markRaw(object(fromFields));
	const initialValues = toRef(props, 'initialValues');

	const {
		handleSubmit,
		submitCount,
		setErrors,
		meta: formMeta,
		values,
	} = useForm<ICreatePostForm>({
		initialValues,
		validationSchema,
	});

	const isValid = useIsFormValid();
	const isSubmitting = useIsSubmitting();

	const formStartMinDate = moment(new Date()).format('YYYY-MM-DD');
	const formStartMaxDate = moment(new Date()).add(1, 'M').subtract(1, 'd').format('YYYY-MM-DD');
	const formEndMinDate = moment(new Date()).add(1, 'd').format('YYYY-MM-DD');
	const formEndMaxDate = moment(new Date()).add(2, 'M').format('YYYY-MM-DD');

	const onSubmitHandler = handleSubmit(async (data) => {
		try {
			await props.submitHandler(data);
		} catch (e) {
			if (e?.error) {
				setErrors(e.error satisfies Record<keyof ICreatePostForm, string>);
			}
		}
	});
</script>
<style lang="scss" scoped>
	.post-create {
		&__preview {
			width: 400px;
		}
	}
</style>
