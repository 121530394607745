import { ref, onBeforeUnmount } from 'vue';
import moment from 'moment';

export const useCurrentTime = () => {
	const format = 'DD MMM HH:mm';
	const currentTime = ref(moment.utc().format(format));
	const updateCurrentTime = () => {
		currentTime.value = moment.utc().format(format);
	};
	const updateTimeInterval = setInterval(updateCurrentTime, 1000);
	onBeforeUnmount(() => {
		clearInterval(updateTimeInterval);
	});
	return {
		currentTime,
	};
};
