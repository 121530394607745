import { date, mixed, string } from 'yup';
import { PostStatusEnum, PostTypeEnum } from './types';

export const fromFields = {
	post_title: string().trim().min(3).max(128).required().label('Title'),
	post_body: string().trim().max(2000).required().label('Message'),
	post_start_date: date().required().label('Start Date'),
	post_end_date: date().required().label('End Date'),
	post_type: mixed<PostTypeEnum>()
		.oneOf<PostTypeEnum>([PostTypeEnum.ALL, PostTypeEnum.ADM, PostTypeEnum.ADV, PostTypeEnum.PUB])
		.required()
		.label('Group target'),
	post_status: mixed<PostStatusEnum>()
		.oneOf<PostStatusEnum>([PostStatusEnum.DRAFT, PostStatusEnum.ACTIVE])
		.required()
		.label('Status'),
};

export const postTypes = [
	{ id: PostTypeEnum.ALL, label: 'All users' },
	{ id: PostTypeEnum.ADM, label: 'Only adm' },
	{ id: PostTypeEnum.ADV, label: 'Only adv' },
	{ id: PostTypeEnum.PUB, label: 'Only pub' },
] satisfies Array<{ id: PostTypeEnum; label: string }>;

export const postStatuses = [
	{ id: PostStatusEnum.DRAFT, label: 'Draft' },
	{ id: PostStatusEnum.ACTIVE, label: 'Active' },
] satisfies Array<{ id: PostStatusEnum; label: string }>;

export const postReportStatuses = [...postStatuses, { id: PostStatusEnum.DELETED, label: 'Deleted' }] satisfies Array<{
	id: PostStatusEnum;
	label: string;
}>;
