import type { ReportResponse } from '@/@types/local';

export enum PostTypeEnum {
	PUB = 'pub',
	ADV = 'adv',
	ADM = 'adm',
	ALL = 'all',
}

export enum PostStatusEnum {
	DRAFT = 'draft',
	ACTIVE = 'active',
	PAUSED = 'paused',
	DELETED = 'deleted',
}

export interface ICreatePostForm {
	post_title: string;
	post_body: string;
	post_start_date: string;
	post_end_date: string;
	post_type: PostTypeEnum;
	post_status: PostStatusEnum.DRAFT | PostStatusEnum.ACTIVE;
}

export interface IPost {
	post_id: number;
	post_title: string;
	post_body: string;
	post_start_date: string;
	post_end_date: string;
	post_created_at: string;
	man_user_id: number;
	man_user_name?: string;
	man_user_email: string;
	post_type: PostTypeEnum;
	post_status: PostStatusEnum.DRAFT | PostStatusEnum.ACTIVE;
}

export type TReportPost = ReportResponse.Report<{
	post_id: number;
	post_title: string;
	post_body: string;
	post_start_date: string;
	post_end_date: string;
	post_created_at: string;
	man_user_id: number;
	man_user_name?: string;
	man_user_email: string;
	post_type: PostTypeEnum;
	post_status: PostStatusEnum.DRAFT | PostStatusEnum.ACTIVE;
	post_ts: string;
}>;

export interface IClientPost {
	post_id: number;
	post_title: string;
	post_body: string;
	post_start_date: string;
	post_end_date: string;
	post_created_at: string;
}
