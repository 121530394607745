<template>
	<div class="user-post" :class="{ 'user-post--preview': props.preview }">
		<div class="user-post__content">
			<div class="user-post__header">
				<div class="user-post__header__title" @click="onClickHandler">
					{{ props.post_title }}
				</div>
				<div class="user-post__header__time">
					{{ castPostTime(props.post_start_date) }}
				</div>
			</div>
			<div class="user-post__body" v-html="markdown" />
		</div>
	</div>
</template>

<script lang="ts" setup>
	import moment from 'moment';
	import { Marked } from 'marked';
	import { computedAsync } from '@vueuse/core';
	import type { IClientPost } from './types';

	const props = withDefaults(defineProps<Omit<IClientPost, 'post_id' | 'post_created_at'> & { preview?: boolean }>(), {
		preview: true,
	});

	const emit = defineEmits<{
		click: [];
	}>();

	const castPostTime = (postStartDate: string) => moment(postStartDate).fromNow();

	const marked = new Marked({
		pedantic: true,
		async: true,
	});
	const markdown = computedAsync(async () => await marked.parse(props.post_body));

	const onClickHandler = () => {
		if (props.preview) emit('click');
	};
</script>
<style lang="scss" scoped>
	@use '../../styles/abstracts/variables' as *;

	.user-post {
		$self: &;

		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 16px;

		overflow: hidden;
		width: 100%;

		font-family: $font-family;

		&--preview {
			#{$self}__body {
				overflow: hidden;
			}
		}

		&__content {
			display: flex;
			flex-direction: column;
			gap: 8px;

			overflow-y: auto;

			font-size: 14px;
			font-weight: 400;
		}

		&__header {
			display: inline-flex;
			justify-content: space-between;
			gap: 12px;

			&__title {
				color: var(--txt-primary);
				font-weight: 600;
			}

			&__time {
				color: var(--txt-secondary--glimpse);
				flex-shrink: 0;
			}
		}

		&__body {
			line-height: 22px;
			color: var(--txt-secondary);

			:deep img {
				width: 100%;
				border-radius: 16px;
			}

			> :first-child {
				margin-top: unset;
			}

			> :last-child {
				margin-bottom: unset;
			}
		}
	}
</style>
