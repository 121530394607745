import { useModal, useModalSlot } from 'vue-final-modal';
import { useAsyncState } from '@vueuse/core';
import { computed, onMounted, ref } from 'vue';

import moment from 'moment';
import type { IClientPost } from '../types';
import Ajax from '@/helpers/ajax';
import useToastr from '@/composables/useToastr';
import BaseModal from '@/components/BaseModal';

import UserService from '@/services/local/UserService';
import PostsList from '../PostsList.vue';

export const usePostsListModal = () => {
	const toastr = useToastr();

	const { state, isLoading, execute } = useAsyncState<Array<IClientPost>>(
		async () => {
			try {
				const { response } = await Ajax.get(`/posts/user/`);
				return response || [];
			} catch {
				toastr.e?.('The post list has not been received');
			}
		},
		[],
		{
			immediate: true,
		}
	);

	const postTs = ref<string | undefined>(UserService.currentUser.posts_delivered_ts);
	const newPostList = computed(() =>
		state.value.filter(({ post_created_at }) =>
			postTs.value !== undefined ? moment(postTs.value).isSameOrBefore(post_created_at) : true
		)
	);
	const hasNewPost = computed(() => !!newPostList.value.length);

	const markAsViewedHandler = async () => {
		const { response } = await Ajax.post(`/posts/user/ts`, {});
		postTs.value = response.delivered_at as string;
		await execute();
	};

	const openedHandler = async () => {
		if (hasNewPost.value) {
			await markAsViewedHandler();
		}
	};

	onMounted(() => {
		UserService.on('change_posts_delivered_ts', (value) => {
			postTs.value = value;
		});
	});

	const { open, close } = useModal({
		component: BaseModal,
		attrs: {
			title: 'News Feed',
			width: 441,
			height: '100%',
			right: true,
			withoutBorder: true,
			onOpened: openedHandler,
		},
		slots: {
			default: useModalSlot({
				component: PostsList,
				attrs: {
					posts: state,
					isLoading,
				},
			}),
		},
	});

	return {
		open,
		close,
		hasNewPost,
	};
};
