import type { CSSProperties } from 'vue';

export function isDef<T>(val: T): val is NonNullable<T> {
	return val !== undefined && val !== null;
}

export function isNumeric(val: string | number): val is string {
	return typeof val === 'number' || /^\d+(\.\d+)?$/.test(val);
}

export function addUnit(value?: string | number | null, unit = 'px'): string | undefined {
	if (!isDef(value)) {
		return undefined;
	}

	return isNumeric(value) ? `${value}${unit}` : String(value);
}

export function getSizeStyle(originSize?: string | number): CSSProperties | undefined {
	if (isDef(originSize)) {
		const size = addUnit(originSize);
		return {
			width: size,
			height: size,
		};
	}
}
