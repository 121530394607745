<template>
	<div :class="['card', { 'card--collapsed': !isOpenTab, 'card--first-order': isFirstInOrder }]" @click="expandHandler">
		<div v-if="isShowHeader" class="card__head-icon">
			<h2 v-if="isShowTitle" class="card__heading text-headline-3">
				{{ title }}
				<i v-if="tooltipText" v-tooltip.bottom="tooltipText" class="card__tooltip" />
			</h2>
			<button
				v-if="collapsed"
				:class="[
					'table-button',
					'table-button--open',
					'table-button--heading-icon',
					{ 'table-button--close': isOpenTab },
				]"
				@click.stop.prevent="toggleHandler"
			>
				Expand
			</button>
		</div>
		<slot v-if="isOpenTab" />
	</div>
</template>

<script lang="ts">
	import { computed, defineComponent } from 'vue';
	import { useVModel } from '@vueuse/core';
	import { cardSharedProps } from './shared';

	export default defineComponent({
		name: 'Card',

		props: cardSharedProps,

		setup(props, { emit }) {
			const isOpenTab = useVModel(props, 'modelValue', emit, { passive: true });
			const isShowTitle = computed(() => props.title || props.tooltipText);
			const isShowHeader = computed(() => isShowTitle.value || props.collapsed);

			const expandHandler = () => {
				if (props.collapsed && isOpenTab.value === false) {
					isOpenTab.value = true;
				}
			};

			const toggleHandler = () => {
				if (props.collapsed) {
					isOpenTab.value = !isOpenTab.value;
				}
			};

			return { isOpenTab, isShowHeader, isShowTitle, expandHandler, toggleHandler };
		},
	});
</script>
