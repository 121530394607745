import $ from 'jquery';
import EventBusService from '../services/local/EventBusService';
import { IS_NEW_API, SERVER_URL } from './const';

export default {
	async get(url, signal = null, data /* todo: WTF?? почему отсутствовала передача параметров? */) {
		if (url.indexOf('/') === 0) {
			url = url.substr(1);
		}

		if (data) {
			url += '?' + new URLSearchParams(data).toString();
		}

		const resp = await fetch(`${SERVER_URL}/${url}`, this.getParams('GET', null, signal));
		if (resp.status === 401) {
			await EventBusService.emit('Ajax::unauthorized');
			return { error: { code: 401, message: 'Unauthorized' } };
		} else {
			return this.updateBalance(await resp.json());
		}
	},

	async fetchAsBlob(url, method = 'GET', data = {}) {
		const params = this.getParams(method, data);
		const resp = await fetch(url, params);
		if (resp.status === 200 || resp.status === 201) {
			const headers = resp.headers;
			const data = await resp.blob();
			return {
				headers,
				data,
			};
		}

		if (resp.status === 408) {
			throw { error: { code: 408, message: 'Server is not available' } };
		} else {
			throw { error: { code: resp.status, message: resp.statusText } };
		}
	},

	async getBulk(urls) {
		const keys = Object.keys(urls);
		const list = keys.map((key) => {
			return this.get(urls[key]);
		});
		const resp = await Promise.all(list);
		const result = {};

		for (let i = 0; i < resp.length; i++) {
			if (resp[i].response) {
				result[keys[i]] = resp[i].response;
			} else {
				throw new Error(`Response error: ${JSON.stringify(resp[i].error)}`);
			}
		}

		if (resp[0]?.balance_current) {
			this.updateBalance(resp[0]);
		}

		return result;
	},

	async post(url, data, dataType = 'json') {
		if (url.indexOf('/') === 0) {
			url = url.substr(1);
		}

		const resp = await fetch(`${SERVER_URL}/${url}`, this.getParams('POST', data));

		if (resp.status === 401) {
			await EventBusService.emit('Ajax::unauthorized');
			return { error: { code: 401, message: 'Unauthorized' } };
		} else {
			return {
				...this.updateBalance(await resp[dataType]()),
				status: resp.status,
			};
		}
	},

	async postDataAsJson(url, data = {}) {
		if (url.indexOf('/') === 0) {
			url = url.substr(1);
		}

		const resp = await fetch(`${SERVER_URL}/${url}`, {
			method: 'POST',
			credentials: 'include',
			mode: 'cors',
			cache: 'no-cache',
			redirect: 'follow',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		});

		if (resp.status === 401) {
			await EventBusService.emit('Ajax::unauthorized');
			return { error: { code: 401, message: 'Unauthorized' } };
		} else if (resp.status === 500 || resp.status === 403) {
			return { error: { code: resp.status, message: resp.statusText } };
		} else {
			return this.updateBalance(await resp.json());
		}
	},

	async postFormData(url, formData) {
		if (url.indexOf('/') === 0) {
			url = url.substr(1);
		}

		const resp = await fetch(`${SERVER_URL}/${url}`, {
			method: 'POST',
			credentials: 'include',
			mode: 'cors',
			cache: 'no-cache',
			redirect: 'follow',
			body: formData,
		});

		if (resp.status === 401) {
			await EventBusService.emit('Ajax::unauthorized');
			return { error: { code: 401, message: 'Unauthorized' } };
		} else if (resp.status === 500 || resp.status === 403) {
			return { error: { code: resp.status, message: resp.statusText } };
		} else {
			return this.updateBalance(await resp.json());
		}
	},

	async report(url, params = {}, signal = null, force = false) {
		let link = `${url}?${$.param(params)}`;

		if (force) {
			link += '&force=1';
		}

		try {
			const resp = await this.get(link, signal);

			if (resp.error || !resp.response) {
				return resp;
			}

			if (resp.response.reportId) {
				return await this.awaitReport(resp.response.reportId, signal);
			}

			return resp;
		} catch (e) {
			if (e.name === 'AbortError') {
				return null;
			}

			return { error: { code: 500 } };
		}
	},

	async awaitReport(reportId, signal) {
		const resp = await this.get(`common/report/?reportId=${reportId}`, signal);

		if (resp.error || !resp.response) {
			return resp;
		}

		if (resp.response.ready) {
			return { response: resp.response.report };
		} else {
			await delay(1000, signal);

			return await this.awaitReport(reportId, signal);
		}
	},

	updateBalance(res) {
		if (typeof res.balance_current !== 'undefined') {
			EventBusService.emit('Ajax::updateBalance', res.balance_current);
		}

		return res;
	},

	getParams(method = 'GET', data = null, signal = null) {
		const isFile = data instanceof FormData && data.has('file');

		const params = {
			method: method,
			credentials: 'include',
			mode: 'cors',
			cache: 'no-cache',
			redirect: 'follow',
			headers: {
				Accept: 'application/json',
				...(IS_NEW_API && !isFile ? { 'Content-Type': 'application/json' } : {}),
			},
		};

		if (signal) {
			params.signal = signal;
		}

		if (method === 'POST') {
			if (IS_NEW_API && !isFile) {
				params.body = JSON.stringify(data ?? {});
			} else {
				if (data instanceof FormData) {
					// params.headers['Content-Type'] = 'multipart/form-data';
					params.body = data;
				} else {
					params.headers['Content-Type'] = 'application/x-www-form-urlencoded';
					params.body = $.param(data);
				}
			}
		}

		return params;
	},
};

function delay(ms, signal) {
	return new Promise((resolve, reject) => {
		if (signal) {
			signal.addEventListener('abort', reject);
		}

		setTimeout(resolve, ms);
	});
}
