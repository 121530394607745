<template>
	<div v-if="show" class="notification text-body-3">
		<p class="notification__message">
			Looks like you’re using adblock. Some features of our platform may work wrong. Please turn it off or add rollerads.com
			to exceptions.
		</p>
	</div>
</template>

<script>
	export default {
		props: {
			show: Boolean,
		},
	};
</script>
