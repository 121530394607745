<template>
	<div
		v-if="size === 'regular'"
		class="select"
		:class="{
			'select--non-empty': !isEmpty,
			'select--focused': isFocused,
			'select--error': selectError,
			'select--readonly': readonly,
		}"
	>
		<v-select
			:id="id"
			ref="select"
			v-model="selectValue"
			:taggable="taggable"
			:create-option="createOption"
			:reduce="(label) => label[trackBy]"
			:name="name"
			:options="options"
			:label="label"
			:track-by="trackBy"
			:no-drop="readonly"
			:clearable="clearable"
			@update:model-value="$emit('input', selectValue)"
			@search:focus="isFocused = true"
			@search:blur="isFocused = false"
			@paste="onPaste"
		/>
		<span
			class="text-field__label"
			:class="{ 'text-field__label--non-empty': !isEmpty && !isFocused, 'text-field__label--focused': isFocused }"
		>
			{{ text }}
		</span>
		<div v-if="error || selectError" v-tooltip.bottom="error || selectError" class="select__error text-caption" />
		<p class="text-field__error-text text-caption">{{ error || selectError }}</p>

		<transition name="assistive">
			<span
				v-if="required && !selectValue?.length && !isFocused && !selectError"
				class="text-field__assistive text-field__assistive--select text-body-1"
			>
				Required
			</span>
		</transition>
	</div>

	<div v-else-if="size === 'small'">
		<v-select
			v-model="selectValue"
			append-to-body
			:calculate-position="setPosition"
			class="table-filter-select table-filter-select--mass-action"
			:class="{ 'table-filter-select--non-empty': selectValue }"
			:style="{ 'min-width': labelLength + 4 + 'ch' }"
			:reduce="(label) => label[trackBy]"
			:name="name"
			:options="options"
			:label="label"
			:track-by="trackBy"
			:placeholder="placeholder"
			@update:model-value="$emit('input', selectValue)"
			@paste="onPaste"
		/>
	</div>
</template>

<script>
	import vSelect from 'vue-select';
	import { createPopper } from '@popperjs/core';

	export default {
		name: 'RaSelect',
		components: {
			vSelect,
		},

		compatConfig: {
			COMPONENT_V_MODEL: true,
		},

		props: {
			name: {
				type: String,
				default: null,
			},
			text: {
				type: String,
				default: null,
			},
			value: {
				type: [String, Array],
				default: '',
			},
			required: Boolean,
			disabled: Boolean,
			readonly: {
				type: Boolean,
				default: false,
			},
			options: {
				type: Array,
				default: () => [],
			},
			trackBy: {
				type: String,
				default: 'id',
			},
			label: {
				type: String,
				default: 'label',
			},
			clearable: {
				type: Boolean,
				default: false,
			},
			taggable: {
				type: Boolean,
				default: false,
			},
			createOption: {
				type: Function,
				default: null,
			},
			filterType: {
				type: String,
				default: 'eq',
			},
			size: {
				type: String,
				default: 'regular',
			},
			placeholder: {
				type: String,
				default: null,
			},
			error: {
				type: String,
				default: '',
			},
		},
		emits: ['input'],

		data() {
			return {
				id: `select_${Date.now()}_${Math.round(Math.random() * 99999)}`,
				selectValue: this.value || '',
				selectError: '',
				filterTypeValue: this.filterType,
				isFocused: false,
			};
		},

		computed: {
			isEmpty() {
				return !this.selectValue;
			},

			labelLength() {
				if (this.selectValue) {
					const chosenLabel = this.options.find((x) => x[this.trackBy] === this.selectValue);

					return chosenLabel[this.label].length;
				}

				return 3;
			},
		},

		watch: {
			value() {
				this.selectValue = this.value;
			},
		},

		created() {
			this.$nextTick(() => {
				if (this.value) {
					this.selectValue = this.value;
					this.$emit('input', this.selectValue);
				}
			});
		},

		methods: {
			setPosition(dropdownList, component, { width }) {
				dropdownList.style.width = width;

				const popper = createPopper(component.$refs.toggle, dropdownList, {
					placement: 'bottom-start',
					modifiers: [
						{
							enabled: true,
							phase: 'write',
							fn({ state }) {
								component.$el.classList.toggle('drop-up', state.placement === 'top');
							},
						},
					],
				});

				return () => popper.destroy();
			},

			onPaste(evt) {
				const searchValue = evt.clipboardData.getData('text/plain');
				const option = this.options.find(({ title }) => title === searchValue);
				this.selectValue = option?.id || '';
				this.$emit('input', this.selectValue);
			},

			clear() {
				this.selectValue = '';
			},

			getValue() {
				return this.selectValue;
			},

			setValue(val) {
				this.selectValue = val;
			},

			setError(val) {
				this.selectError = val;
			},

			clearError() {
				this.selectError = '';
			},

			getFilterType() {
				return this.filterTypeValue;
			},
		},
	};
</script>
