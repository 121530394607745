<template>
	<button
		class="cta text-button"
		:class="[
			{ [`cta--img cta--${imgClass}`]: !!imgClass },
			{ 'cta--loading': loading || isLoading },
			{ 'cta--olyImg': !text },
		]"
		:disabled="loading || isLoading || disabled"
		@click.prevent="$emit('click')"
	>
		{{ text }}
	</button>
</template>

<script>
	export default {
		props: {
			imgClass: {
				type: String,
				default: '',
			},
			text: {
				type: String,
				default: '',
			},
			notFilter: {
				type: Boolean,
				default: true,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			loading: {
				type: Boolean,
				default: false,
			},
		},
		emits: ['click'],

		data() {
			return {
				isLoading: false,
			};
		},

		methods: {
			setLoading(val) {
				this.isLoading = val;
			},
		},
	};
</script>
