import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53fe4996"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "post-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.posts, (post) => {
      return (_openBlock(), _createElementBlock("div", {
        key: post.post_id,
        class: "post-list__item"
      }, [
        _createVNode($setup["Post"], _mergeProps(post, {
          onClick: ($event: any) => ($setup.emit('openPost', post))
        }), null, 16 /* FULL_PROPS */, ["onClick"])
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}