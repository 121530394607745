<template>
	<div class="header__personal">
		<p class="text-body-3 server-time">
			<span>{{ currentTime }}</span>
			(UTC)
			<span v-if="GIT_BRANCH">&nbsp;[{{ GIT_BRANCH }}]</span>
		</p>
	</div>
</template>

<script>
	import { useCurrentTime } from '@/composables/useCurrentTime';
	import { GIT_BRANCH } from '@/helpers/const';
	export default {
		setup() {
			const { currentTime } = useCurrentTime();
			return { currentTime, GIT_BRANCH };
		},
	};
</script>
