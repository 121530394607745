<template>
	<div class="header__personal">
		<p class="text-body-3 server-time">
			<span>{{ currentTime }}</span>
			(UTC)
			<span v-if="GIT_BRANCH">&nbsp;[{{ GIT_BRANCH }}]</span>
		</p>

		<router-link
			v-if="user.role_alias !== 'pub-lite'"
			v-tooltip.bottom="'Withdraw'"
			to="/pub/payouts"
			class="balance text-body-3"
		>
			Balance: {{ formatUSD(balance) }}
		</router-link>
	</div>
</template>

<script>
	import { useCurrentTime } from '@/composables/useCurrentTime';
	import { GIT_BRANCH } from '@/helpers/const';
	import User from '../../../services/local/UserService';
	import mixins from '../../../helpers/mixins';

	const { currentTime } = useCurrentTime();

	export default {
		mixins: [mixins],

		data() {
			return {
				account: User.currentUser.account || {},
				user: User.currentUser.user || {},
				currentTime,
				GIT_BRANCH,
			};
		},

		computed: {
			balance() {
				return this.account.balance_current.toFixed(2);
			},
		},
	};
</script>
