<template>
	<label class="text-field text-field--textarea" :for="id">
		<textarea
			:id="id"
			ref="input"
			v-model="inputValue"
			class="text-field__input text-field__input--textarea"
			:class="{ 'text-field__input--error': error || inputError, 'text-field__input--non-empty': value }"
			placeholder=" "
			:name="name"
			:disabled="disabled"
			@input="$emit('input', $event.target.value)"
			@blur="$emit('blur', $event.target.value)"
			@focus="$emit('focus', $event.target.value)"
			@paste="onPaste"
		/>
		<span class="text-field__label">{{ text }}</span>

		<div v-if="error || inputError" class="text-field__error" />
		<p class="text-caption text-field__error-text">{{ error || inputError }}</p>
	</label>
</template>

<script>
	import { defineComponent } from 'vue';

	export default defineComponent({
		name: 'RaTextarea',

		compatConfig: {
			COMPONENT_V_MODEL: true,
		},

		props: {
			name: {
				type: String,
				required: true,
			},
			text: {
				type: String,
				required: true,
			},
			value: {
				type: String,
				default: '',
			},
			required: Boolean,
			disabled: Boolean,
			filterType: {
				type: String,
				default: 'eq',
			},
			isPasteSeparator: {
				type: Boolean,
				default: false,
			},
			error: {
				type: String,
				default: '',
			},
			multiLinePaste: {
				type: Boolean,
				default: false,
			},
		},
		emits: ['input', 'blur', 'focus'],

		data() {
			return {
				id: `inp_${this.name.toLowerCase()}`,
				inputValue: this.value || '',
				inputError: '',
				filterTypeValue: this.filterType,
			};
		},

		computed: {
			isEmpty() {
				return !this.inputValue;
			},
		},

		watch: {
			inputValue(val) {
				if (this.isPasteSeparator && typeof val === 'string') {
					this.inputValue = val
						.replace(/[\n\r\s]/g, ',')
						.replace(/[^\d,]/gi, '')
						.replace(/,+/g, ',');
				}
			},
			value(newValue) {
				this.inputValue = newValue;
			},
		},

		methods: {
			onPaste(evt) {
				if (!this.multiLinePaste) {
					return;
				}

				const clipboardData = evt.clipboardData.getData('text/plain');

				let searchValue = clipboardData
					.replace(/[\n\r\s]/g, ',')
					.replace(/[^0-9,]/gi, '')
					.replace(/,+/g, ',');

				if (searchValue.length !== 1 && searchValue.includes(',')) {
					searchValue = searchValue.split(',');

					if (searchValue.length) {
						const oldValue = this.getValue()
							.split(',')
							.filter((id) => !!id);

						this.setValue([...new Set([...oldValue, ...searchValue])].join(','));

						evt.preventDefault();
					}
				}

				this.$emit('input', this.getValue());
			},

			clear() {
				this.inputValue = '';
			},

			getValue() {
				return this.inputValue;
			},

			setValue(val) {
				this.inputValue = val;
			},

			getError() {
				return this.inputError;
			},

			setError(val) {
				this.inputError = val;
			},

			clearError() {
				this.inputError = '';
			},

			getFilterType() {
				return this.filterTypeValue;
			},
		},
	});
</script>
