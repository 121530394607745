import Ajax from './ajax';

export const searchCarriers = async (val) => {
	if (!val) {
		return false;
	}

	const res = await Ajax.get(`/misc/carriers?query=${val}`);
	const list = res.response && res.response ? res.response : [];

	return list.map((x) => {
		return { value: x.value };
	});
};

export const makeTitle = (...crumbs) => {
	return crumbs
		.filter((crumb) => crumb)
		.reverse()
		.join(' · ');
};
