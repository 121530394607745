<template>
	<vue-final-modal
		data-class="base-modal-wrapper"
		overlay-class="base-modal-overlay"
		content-class="base-modal"
		:data-model-position="position"
		:modal-id="modalId"
		:content-style="cssStyle"
		:content-transition="contentTransition"
		@closed="emit('closed')"
		@opened="emit('opened')"
	>
		<div class="base-modal__header">
			<div class="base-modal__title">
				<slot v-if="$slots.title" name="title" :title="title" />
				<div v-else class="base-modal__title--default">{{ title }}</div>
			</div>
			<button v-if="closeable" class="header-button base-modal__cross" @click="closeHandler" />
		</div>
		<div class="base-modal__content">
			<slot name="default" :close-handler="closeHandler" />
		</div>
		<div v-if="$slots.footer" class="base-modal__footer">
			<slot name="footer" :close-handler="closeHandler" />
		</div>
	</vue-final-modal>
</template>

<script lang="ts" setup>
	import { computed } from 'vue';
	import { useVfm, VueFinalModal } from 'vue-final-modal';
	import type { CSSProperties } from 'vue';
	import { addUnit } from '@/helpers/unit';

	const props = withDefaults(
		defineProps<{
			title?: string;
			closeable?: boolean;
			width?: string | number;
			height?: string | number;
			maxWidth?: string | number;
			minWidth?: string | number;
			maxHeight?: string | number;
			minHeight?: string | number;
			right?: boolean;
			withoutBorder?: boolean;
			padding?: string;
		}>(),
		{
			title: undefined,
			closeable: true,
			width: 'auto',
			height: 'auto',
			maxWidth: undefined,
			minWidth: '200px',
			maxHeight: undefined,
			minHeight: undefined,
			padding: undefined,
		}
	);

	const emit = defineEmits<{
		closed: [];
		opened: [];
	}>();

	const cssStyle = computed<CSSProperties>(() => ({
		width: addUnit(props.width),
		height: addUnit(props.height),
		maxWidth: addUnit(props.maxWidth),
		minWidth: addUnit(props.minWidth),
		minHeight: addUnit(props.minHeight),
		maxHeight: addUnit(props.maxHeight),
		...(props.withoutBorder ? { borderRadius: 0, border: 0 } : {}),
		...(props.padding ? { padding: props.padding } : {}),
	}));

	const contentTransition = computed<string>(() => {
		switch (true) {
			case props.right:
				return 'vfm-slide-right';
			default:
				return 'vfm-slide-up';
		}
	});

	const position = computed(() => (props.right ? 'right' : ''));

	const modalId = Date.now();
	const vfm = useVfm();

	const closeHandler = async (): Promise<void> => {
		await vfm.close(modalId);
	};
</script>

<style src="./BaseModal.scss" lang="scss" />
