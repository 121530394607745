import type { RouteRecordRaw } from 'vue-router';
import { IS_NEW_API, REF_QUERY_KEY, REF_QUERY_SUBID1_KEY, REF_QUERY_SUBID2_KEY, TERMS_URL } from '@/helpers/const';
import UserService from '@/services/local/UserService';
import EmptyRouterView from '@/components/layouts/EmptyRouterView.vue';
import Error404 from '@/pages/Error404.vue';
import { isFeatureEnabled, isNotRTBPub } from '@/router/middleware';
import { featurePermissionEnum } from '@/pages/adm/presets/shares';
/*
 dev
*/
const DevExecutor = () => import(/* webpackChunkName: "dev" */ '@/pages/dev/Executor.vue');
const DevEngines = () => import(/* webpackChunkName: "dev" */ '@/pages/dev/Engines.vue');
const DevDomains = () => import(/* webpackChunkName: "dev" */ '@/pages/dev/Domains.vue');
const DevCommon = () => import(/* webpackChunkName: "dev" */ '@/pages/dev/Common.vue');
const DevMain = () => import(/* webpackChunkName: "dev" */ '@/pages/dev/DevMain.vue');
/*
 adm
*/
const AdmAdvertisers = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/Advertisers.vue');
const AdmAdvertiserShow = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/AdvertiserShow.vue');
const AdmCampaigns = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/Campaigns.vue');
const AdmCampaignShow = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/CampaignShow.vue');
const AdmCampaignsLog = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/CampaignsLog.vue');
const AdmStatistics = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/Statistics.vue');
const AdmPublishers = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/Publishers.vue');
const AdmUsers = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/Users.vue');
const AdmRates = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/Rates.vue');
const AdmPub = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/publishers/Publisher.vue');
const AdmSites = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/publishers/Sites.vue');
const AdmSiteShow = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/publishers/SiteShow.vue');
// const AdmEarnings = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/publishers/Earnings.vue');
const AdmPayments = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/publishers/Payments.vue');
const AdmModerates = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/Moderates.vue');
const AdmCreatives = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/Creatives.vue');
const AdmFinances = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/Finances.vue');
const AdmFinancesModerate = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/FinancesModerate.vue');
const AdmProtocols = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/Protocols.vue');
const AdmBidders = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/Bidders.vue');
const AdmBidderNew = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/BidderNew.vue');
const AdmZones = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/Zones.vue');
const AdmZoneShow = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/ZoneShow.vue');
const AdmPretargetings = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/pretargetings/Pretargeting.vue');
const AdmPresets = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/presets/Presets.vue');
const AdmPresetsForm = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/presets/PresetsForm.vue');
const AdmInvoices = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/invoices/Invoices.vue');
const AdmInvoiceForm = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/invoices/InvoiceForm.vue');
const AdmReferrals = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/Referrals.vue');
const AdmComments = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/Comments.vue');
const AdmModerateCreative = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/ModerateCreative.vue');
const AdmPostCreate = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/posts/PostCreate.vue');
const AdmPostEdit = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/posts/PostEdit.vue');
const AdmPostReport = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/posts/PostReport.vue');
const AdmPromo = () => import(/* webpackChunkName: "adm" */ '@/pages/adm/promo/Promo.vue');
/*
 pub
*/
const PubStatistics = () => import(/* webpackChunkName: "pub" */ '@/pages/pub/Statistics.vue');
const PubStatisticsSender = () => import(/* webpackChunkName: "pub" */ '@/pages/pub/StatisticsSender.vue');
const PubSites = () => import(/* webpackChunkName: "pub" */ '@/pages/pub/Sites.vue');
const PubMessages = () => import(/* webpackChunkName: "pub" */ '@/pages/pub/Messages.vue');
const PubMessageNew = () => import(/* webpackChunkName: "pub" */ '@/pages/pub/MessageNew.vue');
const PubPayouts = () => import(/* webpackChunkName: "pub" */ '@/pages/pub/Payouts.vue');
const PubPayoutsSettings = () => import(/* webpackChunkName: "pub" */ '@/pages/pub/PayoutsSettings.vue');
const PubPayoutsHistory = () => import(/* webpackChunkName: "pub" */ '@/pages/pub/PayoutsHistory.vue');
const PubEarnings = () => import(/* webpackChunkName: "pub" */ '@/pages/pub/Earnings.vue');
// const PubTracking = () => import(/* webpackChunkName: "pub" */ '@/pages/pub/Tracking.vue');
const PubProfile = () => import(/* webpackChunkName: "pub" */ '@/pages/pub/Profile.vue');
const PubWPPlugin = () => import(/* webpackChunkName: "pub" */ '@/pages/pub/WPPlugin.vue');
/*
 adv
*/
const AdvCampaigns = () => import(/* webpackChunkName: "adv" */ '@/pages/adv/Campaigns.vue');
const AdvCampaignNew = () => import(/* webpackChunkName: "adv" */ '@/pages/adv/CampaignNew');
const AdvCreatives = () => import(/* webpackChunkName: "adv" */ '@/pages/adv/Creatives.vue');
const AdvReports = () => import(/* webpackChunkName: "adv" */ '@/pages/adv/Reports.vue');
const AdvTracking = () => import(/* webpackChunkName: "adv" */ '@/pages/adv/Tracking.vue');
const AdvFinances = () => import(/* webpackChunkName: "adv" */ '@/pages/adv/Finances.vue');
const AdvFinancesReport = () => import(/* webpackChunkName: "adv" */ '@/pages/adv/FinancesReport.vue');
const AdvProfile = () => import(/* webpackChunkName: "adv" */ '@/pages/adv/Profile.vue');
const AdvTopupStatus = () => import(/* webpackChunkName: "adv" */ '@/pages/adv/TopupStatus.vue');
const AdvRates = () => import(/* webpackChunkName: "adv" */ '@/pages/adv/Rates.vue');
/*
 common pages
*/
const DocsApi = () => import(/* webpackChunkName: "common" */ '@/pages/docs/Api.vue');
const Login = () => import(/* webpackChunkName: "common" */ '@/pages/auth/Login.vue');
const Signup = () => import(/* webpackChunkName: "common" */ '@/pages/auth/Signup.vue');
const Forgot = () => import(/* webpackChunkName: "common" */ '@/pages/auth/Forgot.vue');
const Reset = () => import(/* webpackChunkName: "common" */ '@/pages/auth/Reset.vue');
const Privacy = () => import(/* webpackChunkName: "common" */ '@/pages/Privacy.vue');
const Unsubscribe = () => import(/* webpackChunkName: "common" */ '@/pages/Unsubscribe.vue');
const Referrals = () => import(/* webpackChunkName: "common" */ '@/pages/Referrals.vue');

export const routes: Array<RouteRecordRaw> = [
	{ path: '/:pathMatch(.*)*', name: '404', component: Error404, meta: { heading: 'Error 404' } },
	{
		path: '/dev',
		component: DevMain,
		redirect: '/dev/engines',
		children: [
			{
				path: 'engines',
				component: DevEngines,
				meta: { heading: 'Engines' },
			},
			{
				path: 'domains',
				component: DevDomains,
				meta: { heading: 'Domains' },
			},
			{
				path: 'common',
				component: DevCommon,
				meta: { heading: 'Common' },
			},
			{
				path: 'executor',
				component: DevExecutor,
				meta: { heading: 'Executor' },
			},
		],
	},

	{ path: '/adm/404', component: Error404, meta: { heading: 'Error 404' } },

	{
		path: '/adm/advertisers',
		component: EmptyRouterView,
		children: [
			{
				path: '',
				component: AdmAdvertisers,
				meta: { heading: 'Advertisers' },
			},
			{
				path: ':id',
				component: AdmAdvertiserShow,
				meta: {
					heading: 'Edit advertiser',
					isTabs: true,
					previous: { heading: 'Advertisers', to: '/adm/advertisers' },
				},
			},
			{
				path: ':id/:tab',
				component: AdmAdvertiserShow,
				meta: {
					heading: 'Edit advertiser',
					isTabs: true,
					previous: { heading: 'Advertisers', to: '/adm/advertisers' },
				},
			},
		],
	},
	{
		path: '/adm/campaigns',
		component: EmptyRouterView,
		children: [
			{
				path: '',
				component: AdmCampaigns,
				meta: { heading: 'Campaigns', isTabs: true },
			},
			{
				path: ':id',
				component: AdmCampaignShow,
				meta: { heading: 'Show campaign', previous: { heading: 'Campaigns', to: '/adm/campaigns' } },
			},
		],
	},
	{ path: '/adm/history', component: AdmCampaignsLog, meta: { heading: 'Campaigns history', isTabs: true } },
	{ path: '/adm/statistics', component: AdmStatistics, meta: { heading: 'Statistics' } },
	{ path: '/adm/users/:tab', component: AdmUsers, meta: { heading: 'Users', isTabs: true } },

	{ path: '/adm/rates', component: AdmRates, meta: { heading: 'Rates' } },

	{
		path: '/adm/publishers',
		component: EmptyRouterView,
		children: [
			{ path: '', component: AdmPublishers, meta: { heading: 'Publishers' } },
			{
				path: ':id/general',
				component: AdmPub,
				meta: {
					heading: 'Edit publisher',
					isTabs: true,
					previous: { heading: 'Publishers', to: '/adm/publishers' },
				},
				props: { currentPage: 'general' },
			},
			{
				path: ':id/finance',
				component: AdmPub,
				meta: {
					heading: 'Edit publisher',
					isTabs: true,
					previous: { heading: 'Publishers', to: '/adm/publishers' },
				},
				props: { currentPage: 'finance' },
			},
			{
				path: ':id/settings',
				component: AdmPub,
				meta: {
					heading: 'Edit publisher',
					isTabs: true,
					previous: { heading: 'Publishers', to: '/adm/publishers' },
				},
				props: { currentPage: 'settings' },
			},
			{
				path: ':id/manager',
				component: AdmPub,
				meta: {
					heading: 'Edit publisher',
					isTabs: true,
					previous: { heading: 'Publishers', to: '/adm/publishers' },
				},
				props: { currentPage: 'manager' },
			},
			{
				path: ':id/filters',
				component: AdmPub,
				meta: {
					heading: 'Edit publisher',
					isTabs: true,
					previous: { heading: 'Publishers', to: '/adm/publishers' },
				},
				props: { currentPage: 'filters' },
			},
			{
				path: ':id/costs',
				component: AdmPub,
				meta: {
					heading: 'Edit publisher',
					isTabs: true,
					previous: { heading: 'Publishers', to: '/adm/publishers' },
				},
				props: { currentPage: 'costs' },
			},
			{
				path: ':id/bidfloors',
				component: AdmPub,
				meta: {
					heading: 'Edit publisher',
					isTabs: true,
					previous: { heading: 'Publishers', to: '/adm/publishers' },
				},
				props: { currentPage: 'bidfloors' },
			},
			{
				path: ':id/postbacks',
				component: AdmPub,
				meta: {
					heading: 'Edit publisher',
					isTabs: true,
					previous: { heading: 'Publishers', to: '/adm/publishers' },
				},
				props: { currentPage: 'postbacks' },
			},
		],
	},

	{
		path: '/adm/sites',
		component: EmptyRouterView,
		children: [
			{
				path: '',
				component: AdmSites,
				meta: { heading: 'Sites' },
			},
			{
				path: ':id',
				component: AdmSiteShow,
				meta: { heading: 'Edit site', previous: { heading: 'Sites', to: '/adm/sites' } },
			},
		],
	},
	{ path: '/adm/payouts', component: AdmPayments, meta: { heading: 'Payouts' } },
	// { path: '/adm/earnings', component: AdmEarnings },

	{ path: '/adm/moderates', component: AdmModerates, meta: { heading: 'Moderate campaigns' } },
	{ path: '/adm/moderates/:hash(\\d+)', component: AdmModerateCreative, meta: { heading: 'Moderate creative' } },
	{ path: '/adm/creatives', component: AdmCreatives, meta: { heading: 'Creatives Library' } },
	{ path: '/adm/finances', component: AdmFinances, meta: { heading: 'Finances', isTabs: true } },
	{
		path: '/adm/finances-moderate',
		component: AdmFinancesModerate,
		meta: { heading: 'Pending payments', isTabs: true },
	},

	{ path: '/adm/protocols', component: AdmProtocols, meta: { heading: 'Protocols', isTabs: true } },
	{
		path: '/adm/bidders',
		component: EmptyRouterView,
		children: [
			{
				path: '',
				component: AdmBidders,
				meta: { heading: 'Bidders', isTabs: true },
			},
			{
				path: 'new',
				component: AdmBidderNew,
				meta: { heading: 'New bidder', previous: { heading: 'Bidders', to: '/adm/bidders' } },
			},
			{
				path: ':id/edit',
				component: AdmBidderNew,
				meta: { heading: 'Edit bidder', previous: { heading: 'Bidders', to: '/adm/bidders' } },
			},
		],
	},

	{
		path: '/adm/zones',
		component: EmptyRouterView,
		children: [
			{
				path: '',
				component: AdmZones,
				meta: { heading: 'Zones' },
			},
			{
				path: ':id',
				component: AdmZoneShow,
				meta: { heading: 'Edit zone', isTabs: true, previous: { heading: 'Zones', to: '/adm/zones' } },
				// redirect: (to) => {
				// 	// todo: порнография. Надо переделать.
				// 	const id = to.params.id;

				// 	if (id) {
				// 		return { path: `/adm/zones/${id}/general` };
				// 	}

				// 	return { path: '/adm/zones' };
				// },
			},
			{
				path: ':id/general',
				component: AdmZoneShow,
				meta: { heading: 'Edit zone', isTabs: true, previous: { heading: 'Zones', to: '/adm/zones' } },
				props: { currentPage: 'general' },
			},
			{
				path: ':id/filters',
				component: AdmZoneShow,
				meta: { heading: 'Edit zone', isTabs: true, previous: { heading: 'Zones', to: '/adm/zones' } },
				props: { currentPage: 'filters' },
			},
			{
				path: ':id/costs',
				component: AdmZoneShow,
				meta: { heading: 'Edit zone', isTabs: true, previous: { heading: 'Zones', to: '/adm/zones' } },
				props: { currentPage: 'costs' },
			},
			{
				path: ':id/bidfloors',
				component: AdmZoneShow,
				meta: { heading: 'Edit zone', isTabs: true, previous: { heading: 'Zones', to: '/adm/zones' } },
				props: { currentPage: 'bidfloors' },
			},
		],
	},

	{ path: '/adm/pretargetings', component: AdmPretargetings, meta: { heading: 'Pretargetings' } },

	{
		path: '/adm/presets',
		component: AdmPresets,
		meta: { heading: 'Presets', isTabs: true },
		beforeEnter: () => UserService.isResourceAllowed(featurePermissionEnum.PRESETS_ADM_VIEW),
	},

	{
		path: '/adm/preset/:id?',
		component: AdmPresetsForm,
		meta: { heading: 'Presets', previous: { heading: 'presets', to: '/adm/presets' } },
		beforeEnter: (to) =>
			UserService.isResourceAllowed(
				to.params.id ? featurePermissionEnum.PRESETS_ADM_EDIT : featurePermissionEnum.PRESETS_ADM_CREATE
			),
	},

	{ path: '/adm/invoices', component: AdmInvoices, meta: { heading: 'Invoices' } },
	{
		path: '/adm/invoice/:id?',
		component: AdmInvoiceForm,
		meta: { heading: 'Invoice', previous: { heading: 'Invoices', to: '/adm/invoices' } },
	},
	{
		path: '/adm/referrals',
		component: AdmReferrals,
		meta: { heading: 'Referrals' },
	},

	{
		path: '/adm/comments',
		component: AdmComments,
		meta: { heading: 'Comments' },
	},

	{
		path: '/adm/posts',
		component: EmptyRouterView,
		children: [
			{
				path: '',
				name: 'AdmPostReport',
				component: AdmPostReport,
				meta: { heading: 'News Feed', isTabs: true },
			},
			{
				path: 'new',
				name: 'AdmPostCreate',
				component: AdmPostCreate,
				meta: { heading: 'Create a new post', previous: { heading: 'News Feed', to: '/adm/posts' } },
			},
			{
				path: ':id(\\d+)',
				name: 'AdmPostEdit',
				component: AdmPostEdit,
				meta: { heading: 'Edit post', previous: { heading: 'News Feed', to: '/adm/posts' } },
			},
		],
	},

	{ path: '/adm/promo', component: AdmPromo, meta: { heading: 'Promocodes', isTabs: true } },

	{
		path: '/pub/statistics',
		redirect: `/pub/statistics/revenue`,
		component: EmptyRouterView,
		children: [
			{
				path: 'revenue',
				component: PubStatistics,
				meta: { heading: 'Statistics', isTabs: true },
			},
			{
				path: 'messages',
				component: PubStatisticsSender,
				meta: { heading: 'Statistics', isTabs: true },
			},
		],
	},
	{
		path: '/pub/sites',
		component: PubSites,
		meta: { heading: 'Sites' },
		beforeEnter: [isNotRTBPub],
	},

	{
		path: '/pub/messages',
		component: EmptyRouterView,
		beforeEnter: [isFeatureEnabled('sender'), isNotRTBPub],
		children: [
			{
				path: '',
				component: PubMessages,
				meta: { heading: 'Messages' },
			},
			{
				path: 'new',
				component: PubMessageNew,
				meta: {
					heading: 'New message',
					previous: { heading: 'Messages', to: '/pub/messages' },
				},
			},
			{
				path: ':id/edit',
				component: PubMessageNew,
				meta: {
					heading: 'Edit message',
					previous: { heading: 'Messages', to: '/pub/messages' },
				},
			},
		],
	},

	// { path: '/pub/tracking', component: PubTracking },
	{
		path: '/pub/payouts',
		component: PubPayouts,
		meta: { heading: 'Payouts', isTabs: true },
	},
	{
		path: '/pub/payouts-settings',
		component: PubPayoutsSettings,
		meta: { heading: 'Payouts', isTabs: true },
	},
	{
		path: '/pub/payouts-history',
		component: PubPayoutsHistory,
		meta: { heading: 'Payouts', isTabs: true },
	},
	{
		path: '/pub/earnings',
		component: PubEarnings,
		meta: { heading: 'Earnings' },
	},
	{ path: '/pub/profile', component: PubProfile, meta: { heading: 'Profile' } },
	{ path: '/pub/wp-plugin', component: PubWPPlugin, meta: { heading: 'WPPlugin' } },

	{
		path: '/adv/campaigns',
		component: EmptyRouterView,
		children: [
			{
				path: '',
				component: AdvCampaigns,
				meta: { heading: 'Campaigns' },
			},
			{
				path: 'new',
				component: AdvCampaignNew,
				meta: { heading: 'New campaign', previous: { heading: 'Campaigns', to: '/adv/campaigns' } },
			},
			{
				path: ':id/edit',
				component: AdvCampaignNew,
				meta: { heading: 'Edit campaign', previous: { heading: 'Campaigns', to: '/adv/campaigns' } },
			},
		],
	},
	{ path: '/adv/creatives', component: AdvCreatives, meta: { heading: 'Creatives Library' } },
	{ path: '/adv/reports', component: AdvReports, meta: { heading: 'Reports' } },
	{ path: '/adv/tracking', component: AdvTracking, meta: { heading: 'Tracking' } },
	{
		path: '/adv/finances',
		component: AdvFinances,
		meta: { heading: 'Finance', isTabs: true },
		async beforeEnter(to, from, next) {
			const isPending = UserService.currentUser.account?.account_status === 'pending';
			if (isPending) {
				next('/adv/campaigns');
			} else {
				next();
			}
		},
	},
	{ path: '/adv/finances-report', component: AdvFinancesReport, meta: { heading: 'History', isTabs: true } },
	{ path: '/adv/profile', component: AdvProfile, meta: { heading: 'Profile' } },
	{ path: '/adv/topup-status', component: AdvTopupStatus, meta: { heading: 'Payment status' } },
	{ path: '/adv/rates', component: AdvRates, meta: { heading: 'Rates' } },

	{ path: '/docs', component: DocsApi, meta: { heading: 'API' } },
	{ path: '/docs/:doc', component: DocsApi, meta: { heading: 'API' } },

	{ path: '/auth/login', component: Login },
	{ path: '/auth/signup', component: Signup },
	{ path: '/auth/signup-advertiser', redirect: '/auth/signup' },
	{ path: '/auth/signup-publisher', redirect: '/auth/signup' },
	{ path: '/auth/forgot', component: Forgot },
	{ path: '/auth/reset', component: Reset },
	{
		path: '/logout',
		component: EmptyRouterView,
		async beforeEnter(to, from, next) {
			await UserService.logout();
			next('/auth/login');
		},
	},

	...(IS_NEW_API ? [{ path: '/2fa/:uid/:h/:action', component: import('@/pages/auth/TwoFA.vue') }] : []),

	{
		path: '/guidelines',
		redirect: () => {
			window.location.replace(TERMS_URL);

			return '/terms';
		},
	},
	{ path: '/privacy', component: Privacy, meta: { heading: 'Privacy policy' } },
	{ path: '/unsubscribe', component: Unsubscribe, meta: { heading: 'Unsubscribe' } },
	{ path: '/referrals', component: Referrals, meta: { heading: 'Referrals' } },

	{
		path: '/',
		redirect: () => UserService.getHomepage(),
	},
	{
		path: '/ref/:token',
		redirect: (to) => ({
			path: '/auth/signup',
			query: {
				[REF_QUERY_SUBID1_KEY]: to.query[REF_QUERY_SUBID1_KEY],
				[REF_QUERY_SUBID2_KEY]: to.query[REF_QUERY_SUBID2_KEY],
				[REF_QUERY_KEY]: to.params.token,
			},
		}),
	},
];
