// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-03f949ac]:root {
  --primary: #3d65fd;
  --primary--glimpse: #f3f5ff;
  --primary--touch: #dbe2ff;
  --error: #d90b0b;
  --txt-primary: #1a1f36;
  --txt-secondary: #3c4257;
  --txt-secondary--glimpse: #b5b7bf;
  --bg-primary: #ffffff;
  --bg-field: #f7f7f7;
  --bg-border: #e6e6e6;
  --bg-border-light: #d8d9dd;
  --bg-border-primary: #c3cffe;
  --bg-border-ok: #1bc436;
  --bg-border-error: #d2bcb4;
  --txt-ok: #0e6245;
  --txt-error: #a41c4e;
  --txt-error-contrast: #a41c4e;
  --txt-await: #3d4eac;
  --bg-alert: #ffebb2;
  --bg-info: #eceef7;
  --bg-ok: #cbf4c9;
  --bg-ok-light: #e2f6e1;
  --bg-await: #daebfd;
  --bg-error: #f9e3de;
  --border-topup-completed: #cbf4c9;
  --bg-topup-completed: #99d68c;
  --border-topup-pending: #daebfd;
  --bg-topup-pending: #8caed6;
  --border-topup-failed: #f9e3de;
  --bg-topup-failed: #d6958c;
  --fast-stat-border: yellowgreen;
}
:root.dark-theme[data-v-03f949ac] {
  --primary: #bac3ff;
  --primary--glimpse: #2b2c35;
  --primary--touch: #505365;
  --error: #d90b0b;
  --txt-primary: #e4e1e6;
  --txt-secondary: #e4e1e6;
  --txt-secondary--glimpse: #b5b7bf;
  --bg-primary: #1b1b1f;
  --bg-field: #23232b;
  --bg-border: #46464e;
  --bg-border-light: #46464e;
  --bg-border-primary: #c3cffe;
  --bg-border-ok: #1bc436;
  --bg-border-error: #4a0003;
  --txt-ok: #7df8d7;
  --txt-error: #ffdad4;
  --txt-error-contrast: #ff8773;
  --txt-await: #cbe6ff;
  --bg-alert: #574500;
  --bg-info: #2a2f42;
  --bg-ok: #005140;
  --bg-ok-light: #004435;
  --bg-await: #004b73;
  --bg-error: #680003;
  --border-topup-completed: #205041;
  --bg-topup-completed: #0d3f2f;
  --border-topup-pending: #1d4a70;
  --bg-topup-pending: #0e3251;
  --border-topup-failed: #5f0f0b;
  --bg-topup-failed: #430b08;
  --fast-stat-border: yellow;
}
.user-post[data-v-03f949ac] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  overflow: hidden;
  width: 100%;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}
.user-post--preview .user-post__body[data-v-03f949ac] {
  overflow: hidden;
}
.user-post__content[data-v-03f949ac] {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  font-size: 14px;
  font-weight: 400;
}
.user-post__header[data-v-03f949ac] {
  display: inline-flex;
  justify-content: space-between;
  gap: 12px;
}
.user-post__header__title[data-v-03f949ac] {
  color: var(--txt-primary);
  font-weight: 600;
}
.user-post__header__time[data-v-03f949ac] {
  color: var(--txt-secondary--glimpse);
  flex-shrink: 0;
}
.user-post__body[data-v-03f949ac] {
  line-height: 22px;
  color: var(--txt-secondary);
}
.user-post__body[data-v-03f949ac] img {
  width: 100%;
  border-radius: 16px;
}
.user-post__body[data-v-03f949ac] > :first-child {
  margin-top: unset;
}
.user-post__body[data-v-03f949ac] > :last-child {
  margin-bottom: unset;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
