<template>
	<header class="header" :class="{ 'header--tabs': $route.meta.isTabs }">
		<template v-if="loggedIn">
			<button
				v-tooltip.bottom="'Toggle menu'"
				class="header-button header-button--menu header__menu"
				:class="{ 'header-button--menu-closed': !isMenuVisible, 'header__menu--open': !isMenuVisible }"
				@click="toggleMenu"
			>
				Menu
			</button>
			<router-link
				v-if="$route.meta.previous"
				v-tooltip.bottom="'Back to ' + $route.meta.previous.heading"
				class="header-button header-button--back header__back"
				:to="$route.meta.previous.to"
			>
				Back
			</router-link>
			<h1 class="text-headline-2 header__title">
				<span v-if="$route.meta.previous">
					<router-link
						:to="$route.meta.previous.to"
						class="text-headline-2 text-headline-2--glimpse header__breadcrumb"
					>
						{{ $route.meta.previous.heading }}
					</router-link>
					<span class="text-headline-2--glimpse">&nbsp;/</span>
				</span>
				{{ $route.meta.heading }}
				<span v-if="$route.params.id">({{ $route.params.id }})</span>
			</h1>

			<adm-top v-if="user.role_type === 'adm'" />
			<adv-top v-if="user.role_type === 'adv'" />
			<pub-top v-if="user.role_type === 'pub'" />

			<div v-if="canImpersonate && user.role_type === 'adm'" class="impersonate header__impersonate text-body-3">
				<input
					ref="impersonate_user"
					class="impersonate__input"
					type="text"
					name="impersonate_user"
					placeholder="Impersonate user"
				/>
			</div>

			<posts-btn class="header__post-list" :show-badge="postListModal.hasNewPost" @click="postListModal.open" />

			<div class="dropdown header-button header-button--profile header__profile">
				<ul class="dropdown__content text-body-3">
					<li v-if="user.role_type === 'adv'">
						<router-link to="/adv/profile">Profile</router-link>
					</li>
					<li v-if="user.role_type === 'pub'">
						<router-link to="/pub/profile">Profile</router-link>
					</li>
					<li @click="$emit('themeChange')">
						<span>{{ isDarkTheme ? 'Light theme' : 'Dark theme' }}</span>
					</li>
					<li>
						<router-link to="/logout">Logout</router-link>
					</li>
				</ul>
			</div>

			<div v-if="isImpersonate" class="impersonate-message text-body-3">
				<p>You are signed in as {{ userEmail() }}</p>
				<div class="impersonate-message__stop" @click="stopImpersonation">Stop Impersonation</div>
			</div>
		</template>
	</header>
</template>

<script>
	import $ from 'jquery';
	import mixins from '@/helpers/mixins';
	import { SERVER_URL } from '@/helpers/const';
	import User from '@/services/local/UserService';
	import EventBus from '@/services/local/EventBusService';
	import { usePostsListModal, PostsBtn } from '@/components/Posts';

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	import autocompleter from '../libs/jquery.autocompleter';

	import AdmTop from './roles/adm/Top';
	import AdvTop from './roles/adv/Top';
	import PubTop from './roles/pub/Top';

	export default {
		name: 'RaHeader',

		components: {
			AdmTop,
			AdvTop,
			PubTop,
			PostsBtn,
		},

		mixins: [mixins],

		props: {
			isDarkTheme: Boolean,
		},
		emits: ['themeChange'],

		data() {
			return {
				user: User.currentUser.user || {},
				real: User.currentUser.real || {},
				loggedIn: User.isLoggedIn(),
				isImpersonate: User.isImpersonate(),
				isMenuVisible: true,
				stopImpersonation: User.stopImpersonation.bind(User),
				postListModal: null,
			};
		},

		computed: {
			canImpersonate() {
				return this.isResourceAllowed('false_user');
			},
		},

		created() {
			User.on(['change', 'change_balance'], (loggedIn) => {
				this.loggedIn = loggedIn;
				this.isImpersonate = User.isImpersonate();
				this.user = User.currentUser.user || {};
				this.real = User.currentUser.real || {};

				this.$nextTick(() => {
					this.reinitAutocomplete();
				});
			});

			this.postListModal = usePostsListModal();
		},

		mounted() {
			this.reinitAutocomplete();

			localStorage.isMenuVisible === 'false' ? (this.isMenuVisible = false) : (this.isMenuVisible = true);
		},

		methods: {
			userEmail() {
				return User.currentUser.user.user_email;
			},

			reinitAutocomplete() {
				$(this.$refs.impersonate_user).autocompleter({
					cache: true,
					source: `${SERVER_URL}/users/suggest`,
					callback: (value) => {
						this.$refs.impersonate_user.value = '';
						User.impersonate(value);
					},
				});
			},

			toggleMenu() {
				EventBus.emit('menuToggled', true);
				this.isMenuVisible = !this.isMenuVisible;
				localStorage.isMenuVisible = this.isMenuVisible;
			},
		},
	};
</script>
