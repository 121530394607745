import { ref, watch } from 'vue';

export type StorageType = 'localStorage' | 'sessionStorage';

export class Storage {
	protected storage_type: StorageType;
	protected ttl: number;

	constructor(storage_type = 'localStorage' as StorageType, ttl = 0) {
		this.storage_type = storage_type;
		this.ttl = ttl;
	}

	get available() {
		return Storage.storageAvailable(this.storage_type);
	}

	set(key, value, ttl = this.ttl) {
		if (this.available) {
			const expiry = ttl > 0 ? new Date().getTime() + ttl : 0;

			try {
				return window[this.storage_type].setItem(key, JSON.stringify({ value, expiry }));
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e);
			}
		}

		return null;
	}

	get<T>(key: string): T | null {
		if (this.available) {
			const itemStr = window[this.storage_type].getItem(key);

			if (!itemStr) {
				return null;
			}

			try {
				const dataStr = window[this.storage_type].getItem(key);

				if (!dataStr) {
					return null;
				}

				const { value, expiry } = JSON.parse(dataStr);

				if (expiry > 0 && new Date().getTime() > expiry) {
					window[this.storage_type].removeItem(key);
				} else {
					return value;
				}
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e);
			}
		}

		return null;
	}

	remove(key: string): void {
		if (this.available) {
			try {
				window[this.storage_type].removeItem(key);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e);
			}
		}
	}

	onChangeStorage(cd) {
		if (this.available) {
			return window.addEventListener('storage', cd, false);
		}
	}

	removeChangeStorage(cd) {
		if (this.available) {
			window.removeEventListener('storage', cd);
		}
	}

	// todo: имхо этому тут не место
	syncKey<T>(key: string, default_value?, ttl?: number) {
		if (this.available) {
			const getValue = () => this.get<T>(key) || default_value;

			const value = ref<T | undefined>(getValue());
			const isLocked = ref(false);

			watch(
				() => value.value,
				(value) => {
					if (isLocked.value === false) {
						this.set(key, value, ttl);
					} else {
						isLocked.value = false; // unlock
					}
				}
			);

			this.onChangeStorage((event) => {
				if (event.key && event.key === key) {
					value.value = getValue();
					isLocked.value = true; // lock
				}
			});

			return value;
		}

		return default_value;
	}

	static storageAvailable(type: StorageType) {
		try {
			const storage = window[type];
			const x = '__storage_test__';
			storage.setItem(x, x);
			storage.removeItem(x);
			return true;
		} catch (e) {
			return false;
		}
	}
}
