<template>
	<div class="manager-badge">
		<div class="manager-badge__content" @mouseenter="onMouseHandler" @mouseleave="onMouseHandler">
			<div class="manager-badge__photo" :class="{ 'manager-badge__photo-extra': isExpanded || isHover || isMenuVisible }">
				<img v-if="imgPath" :src="imgPath" @error="errorImageHandler" />
			</div>
			<div
				v-if="isMenuVisible && !isExpanded"
				class="manager-badge__info-block"
				:class="{ 'manager-badge__info-block__half-bordered': isHover }"
			>
				<div class="manager-badge__information">
					<span class="manager-badge__name" :title="name">{{ name }}</span>
					<span class="manager-badge__position">Personal manager</span>
				</div>
				<div v-if="isHover" class="manager-badge__divider" />
			</div>
			<div v-if="isExpanded" class="manager-badge__expanded">
				<div class="manager-badge__info-block manager-badge__info-block__half-bordered">
					<div class="manager-badge__information">
						<span class="manager-badge__name" :title="name">{{ name }}</span>
						<span class="manager-badge__position">Personal manager</span>
					</div>
					<div class="manager-badge__divider" />

					<div class="manager-badge__contacts">
						<div v-for="contact of filteredContacts" :key="contact.im" class="manager-badge__contact">
							<div class="manager-badge__contact-icon" :style="{ '--mask-social': contact.mask }" />
							<span class="manager-badge__contact-text">
								<a v-if="contact.link" :href="contact.link" target="_blank">{{ contact.nickname }}</a>
								<span v-else>{{ contact.nickname }}</span>
							</span>
							<div class="manager-badge__divider" />
						</div>
					</div>
				</div>
			</div>
			<div v-if="isHover || isExpanded" class="manager-badge__arrow" @click="expandHandler">
				<div class="manager-badge__arrow-icon" :class="{ 'expand-badge__rotated': isExpanded }" />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import { computed, ref } from 'vue';
	import useMenuStore from '@/composables/useMenuStore';
	import { useSocialIcons } from '@/composables/useSocialIcons';

	export default {
		name: 'ManagerBadge',
		props: {
			managerId: {
				type: Number,
				default: null,
			},
			managerName: {
				type: String,
				default: null,
			},
			contacts: {
				type: Array,
				default: () => [],
			},
			accountType: {
				type: String,
				default: 'adv',
			},
		},
		setup(props) {
			const { menuStore } = useMenuStore();
			const isHover = ref(false);
			const imgPath = ref(`/managers/${props.accountType}/${props.managerId}.png`);
			const isExpanded = ref(false);
			const isMenuVisible = computed(() => menuStore.value);
			const { getIcon } = useSocialIcons();

			const filteredContacts = (props.contacts ?? []).map((el) => {
				const socialNet = (el.im ?? '').toLowerCase();
				const iconData = getIcon(socialNet);
				const nickname = socialNet === 'telegram' ? `${el.nickname.replace('@', '')}` : el.nickname;
				return {
					nickname,
					im: socialNet,
					mask: `url('${iconData?.logo}') no-repeat 50% 50%`,
					link: `${iconData?.link}${nickname}`,
				};
			});

			const onMouseHandler = () => {
				isHover.value = !isHover.value;
			};

			const expandHandler = () => {
				isExpanded.value = !isExpanded.value;
			};

			const errorImageHandler = () => {
				imgPath.value = `/managers/default.svg`;
			};

			return {
				imgPath,
				name: props.managerName,
				onMouseHandler,
				expandHandler,
				isHover,
				isExpanded,
				filteredContacts,
				isMenuVisible,
				errorImageHandler,
			};
		},
	};
</script>

<style lang="scss">
	@import 'ManagerBadge';
</style>
