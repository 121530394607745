import { toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card__head-icon"
}
const _hoisted_2 = {
  key: 0,
  class: "card__heading text-headline-3"
}
const _hoisted_3 = {
  key: 0,
  class: "card__tooltip"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['card', { 'card--collapsed': !_ctx.isOpenTab, 'card--first-order': _ctx.isFirstInOrder }]),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.expandHandler && _ctx.expandHandler(...args)))
  }, [
    (_ctx.isShowHeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.isShowTitle)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_2, [
                _createTextVNode(_toDisplayString(_ctx.title) + " ", 1 /* TEXT */),
                (_ctx.tooltipText)
                  ? _withDirectives((_openBlock(), _createElementBlock("i", _hoisted_3, null, 512 /* NEED_PATCH */)), [
                      [
                        _directive_tooltip,
                        _ctx.tooltipText,
                        void 0,
                        { bottom: true }
                      ]
                    ])
                  : _createCommentVNode("v-if", true)
              ]))
            : _createCommentVNode("v-if", true),
          (_ctx.collapsed)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: _normalizeClass([
					'table-button',
					'table-button--open',
					'table-button--heading-icon',
					{ 'table-button--close': _ctx.isOpenTab },
				]),
                onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleHandler && _ctx.toggleHandler(...args)), ["stop","prevent"]))
              }, " Expand ", 2 /* CLASS */))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.isOpenTab)
      ? _renderSlot(_ctx.$slots, "default", { key: 1 })
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}