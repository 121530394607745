// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-list[data-v-53fe4996] {
  margin: 0 -24px -24px -24px;
  padding: 0 24px;
  overflow: auto;
}
.post-list__item[data-v-53fe4996] {
  padding: 24px 0;
}
.post-list__item[data-v-53fe4996]:not(:first-child) {
  border-top: 1px solid var(--bg-border);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
