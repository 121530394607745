import $ from 'jquery';
import * as mixinsRaw from '@/helpers/mixinsRaw';
import User from '../services/local/UserService';

export default {
	methods: {
		...mixinsRaw,

		isResourceAllowed(resource_key) {
			return User.isResourceAllowed(resource_key);
		},

		setError(error) {
			if (error.code) {
				this.$children.forEach((component) => {
					const key = error.elements ? error.error_field : error.error_message;
					const val = error.elements ? error.error_message : error.entity;

					if (component.name === key) {
						component.setError(val);
					}
				});

				return;
			}

			$.each(error, (key, val) => {
				this.$children.forEach((component) => {
					if (component.name === key) {
						component.setError(val);
					}
				});
			});
		},

		clearError() {
			this.$children.forEach((component) => {
				if (typeof component.clearError === 'function') {
					component.clearError();
				}
			});
		},
	},
};
