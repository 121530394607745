<template>
	<section>
		<div class="card">
			<h2 class="card__heading text-headline-3">Page not found</h2>
		</div>
	</section>
</template>

<script>
	import { makeTitle } from '../helpers/misc.js';

	export default {
		metaInfo() {
			return {
				title: makeTitle('RollerAds', '404 :('),
			};
		},
	};
</script>
