<template>
	<metainfo />
	<div id="app">
		<template v-if="loggedIn">
			<menu-adm v-if="role === 'adm'" />
			<menu-adv v-if="role === 'adv'" />
			<menu-pub v-if="role === 'pub'" />

			<main class="authorized" :class="{ 'authorized--menu-collapsed': !isMenuVisible }">
				<div class="authorized__content">
					<ra-header :is-dark-theme="isDarkTheme" @theme-change="toggleTheme" />
					<notification v-if="role !== 'adm'" />
					<ad-block :show="isAdBlock" />
					<router-view />
				</div>
				<ra-footer v-if="role === 'adv' || role === 'pub'" class="authorized__footer" />
			</main>
		</template>

		<template v-else>
			<main>
				<a class="logo non-authorized__logo" href="https://rollerads.com">Main Page</a>
				<router-view class="non-authorized" />
			</main>
		</template>

		<modals-container />
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import { detectAnyAdblocker } from 'vue-adblock-detector';
	import { ModalsContainer } from 'vue-final-modal';
	import EventBus from '@/services/local/EventBusService';
	import User from '@/services/local/UserService';
	import useReferralToken from '@/composables/useReferralToken';
	import useIntercom from '@/composables/useIntercom';
	import RaFooter from '@/components/RaFooter';
	import RaHeader from '../components/RaHeader';
	import MenuAdm from '../components/roles/adm/LeftMenu';
	import MenuAdv from '../components/roles/adv/LeftMenu';
	import MenuPub from '../components/roles/pub/LeftMenu';
	import Notification from '../components/Notification';
	import AdBlock from '../components/AdBlock';

	export default defineComponent({
		components: {
			RaFooter,
			RaHeader,
			MenuAdm,
			MenuAdv,
			MenuPub,
			ModalsContainer,
			Notification,
			AdBlock,
		},

		setup() {
			useReferralToken();
		},

		data() {
			return {
				loggedIn: User.isLoggedIn(),
				isImpersonate: User.isImpersonate(),
				allowBeta: false,
				role: '',
				userId: User?.currentUser?.user?.account_id,
				isMenuVisible: true,
				isDarkTheme: false,
				isAdBlock: false,
				showIntercom: null,
			};
		},

		created() {
			this.showIntercom = useIntercom();

			User.on('change', () => {
				this.updateUser();
			});

			User.on('refresh', () => {
				const homepage = localStorage.getItem('homepage') || User.getHomepage();

				if (this.$route.path !== homepage) {
					this.$router.replace(homepage);
				}

				this.updateUser();
			});

			this.updateUser();

			EventBus.on('menuToggled', (val) => {
				this.isMenuVisible = this.isMenuVisible !== val;
			});

			localStorage.isMenuVisible === 'false' ? (this.isMenuVisible = false) : (this.isMenuVisible = true);

			const hasDarkPreference = window.matchMedia('(prefers-color-scheme: dark)').matches;
			const theme = localStorage.getItem('theme');

			if (theme === 'dark') {
				document.documentElement.classList.toggle('dark-theme');

				return (this.isDarkTheme = true);
			} else if (hasDarkPreference && !theme) {
				document.documentElement.classList.toggle('dark-theme');
				localStorage.setItem('theme', 'dark'); // todo use LocalStorageService.ts

				return (this.isDarkTheme = true);
			} else {
				return (this.isDarkTheme = false);
			}
		},

		mounted() {
			detectAnyAdblocker().then((detected) => {
				if (detected) {
					this.isAdBlock = true;
				}
			});
		},

		methods: {
			updateUser() {
				this.loggedIn = User.isLoggedIn();
				this.isImpersonate = User.isImpersonate();
				this.role = this.loggedIn ? User.currentUser.user.role_type : '';
				this.allowBeta = User.isFeatureEnabled('beta');

				this.showIntercom(this.loggedIn && this.role !== 'adm' && !this.allowBeta);
			},

			toggleTheme() {
				this.isDarkTheme = !this.isDarkTheme;
				localStorage.setItem('theme', this.isDarkTheme ? 'dark' : 'light'); // todo use LocalStorageService.ts
				// can't be done on #app - vars must be applied to body/html to color the overscroll
				document.documentElement.classList.toggle('dark-theme');
			},
		},

		metaInfo() {
			return {
				title: 'RollerAds',
				link: [
					{
						rel: 'apple-touch-icon',
						sizes: '180x180',
						href: `/img/favicons/apple-touch-icon.png?v=3`,
					},
					{
						rel: 'icon',
						type: 'image/png',
						sizes: '32x32',
						href: `/img/favicons/favicon-32x32.png?v=3`,
					},
					{
						rel: 'icon',
						type: 'image/png',
						sizes: '16x16',
						href: `/img/favicons/favicon-16x16.png?v=3`,
					},
					{
						rel: 'manifest',
						href: `/img/favicons/site.webmanifest?v=3`,
					},
					{
						rel: 'mask-icon',
						href: `/img/favicons/safari-pinned-tab.svg?v=3`,
						color: '#0538fb',
					},
					{
						rel: 'shortcut icon',
						href: `/img/favicons/favicon.ico?v=3`,
					},
				],

				meta: [
					{ name: 'msapplication-TileColor', content: '#ffffff' },
					{
						name: 'msapplication-TileImage',
						content: `/img/favicons/mstile-144x144.png?v=3`,
					},
					{
						name: 'msapplication-config',
						content: `/img/favicons/browserconfig.xml?v=3`,
					},
					{ name: 'theme-color', content: '#ffffff', media: '(prefers-color-scheme: light)' },
					{ name: 'theme-color', content: '#1b1b1f', media: '(prefers-color-scheme: dark)' },
				],
			};
		},
	});
</script>

<style lang="scss">
	@import '../styles/main';
</style>
