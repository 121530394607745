import TelegramLogo from '@/assets/icons/telegram.svg';
import SkypeLogo from '@/assets/icons/skype.svg';
import MailLogo from '@/assets/icons/mail.svg';

export const useSocialIcons = () => {
	const mapSocials = new Map([
		[
			'mail',
			{
				logo: MailLogo,
				link: '',
			},
		],
		[
			'skype',
			{
				logo: SkypeLogo,
				link: 'skype:',
			},
		],
		[
			'telegram',
			{
				logo: TelegramLogo,
				link: 'https://t.me/',
			},
		],
	]);

	const getIcon = (socialName: string) => {
		return mapSocials.get(socialName);
	};

	return {
		getIcon,
	};
};
