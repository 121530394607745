// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-btn[data-v-a51b5fd6] {
  position: relative;
}
.post-btn__badge[data-v-a51b5fd6] {
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  position: absolute;
  transform-origin: 100%;
  width: 10px;
  height: 10px;
  background-color: #f87171;
  border-radius: 999px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
