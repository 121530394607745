import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03f949ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-post__content" }
const _hoisted_2 = { class: "user-post__header" }
const _hoisted_3 = { class: "user-post__header__time" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["user-post", { 'user-post--preview': $setup.props.preview }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "user-post__header__title",
          onClick: $setup.onClickHandler
        }, _toDisplayString($setup.props.post_title), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_3, _toDisplayString($setup.castPostTime($setup.props.post_start_date)), 1 /* TEXT */)
      ]),
      _createElementVNode("div", {
        class: "user-post__body",
        innerHTML: $setup.markdown
      }, null, 8 /* PROPS */, _hoisted_4)
    ])
  ], 2 /* CLASS */))
}