<template>
	<div v-if="size === 'regular'" class="card__one-column">
		<div v-if="isChangeableFilter" class="switcher text-body-3">
			<button
				:class="[
					'switcher__label switcher__label--filter',
					['in', 'inset'].includes(filterTypeValue) ? 'switcher__label--include' : 'switcher__label--exclude',
				]"
				@click.prevent="changeFilterType"
			>
				Change
			</button>
		</div>
		<label class="text-field">
			<input
				:id="id"
				ref="input"
				v-model="inputValue"
				class="text-field__input"
				:class="{
					'text-field__input--error': error || inputError,
					'text-field__input--non-empty': inputValue || inputValue === 0,
					'text-field__input--icon-right': iconRight,
				}"
				placeholder=" "
				:type="inputType"
				:name="name"
				:readonly="readonly"
				:maxlength="inputCount ? inputCount : maxLength || ''"
				:autocomplete="autocomplete"
				@input="$emit('input', $event.target.value)"
				@blur="onBlur($event)"
				@focus="onFocus($event)"
			/>
			<span class="text-field__label">{{ text }}</span>
			<slot v-if="$slots.icon" name="icon" />

			<div v-if="error || inputError" v-tooltip.bottom="error || inputError" class="text-field__error" />
			<p v-if="!isNoErrorText" class="text-field__error-text text-caption">{{ error || inputError }}</p>

			<p v-if="!optional && !inputError && hint" class="text-field__hint-text text-caption">{{ hint }}</p>

			<transition name="assistive">
				<span
					v-if="required && !inputValue && !isFocused && !(error || inputError)"
					class="text-field__assistive text-body-1"
				>
					Required
				</span>
				<span v-else-if="optional" class="text-field__assistive text-body-1">Optional</span>

				<p
					v-else-if="(inputValue || isFocused) && !(error || inputError) && count"
					class="text-field__assistive text-body-1"
				>
					<span class="text-body-2">{{ charCount }}</span>
					{{ '/' + inputCount }}
				</p>
			</transition>
		</label>
	</div>

	<label v-else-if="size === 'small'">
		<input
			:id="id"
			ref="input"
			v-model="inputValue"
			v-tooltip.bottom="error || inputError"
			class="table-filter table-filter--inline"
			:class="{ 'table-filter--non-empty': inputValue, 'table-filter--error': error || inputError }"
			:style="{ minWidth: isExtendable && inputValue.length + 5 + 'ch' }"
			:type="inputType"
			:name="name"
			:readonly="readonly"
			:maxlength="inputCount !== null ? inputCount : ''"
			:autocomplete="autocomplete"
			@input="$emit('input', $event.target.value)"
			@blur="$emit('blur', $event.target.value)"
			@focus="$emit('focus', $event.target.value)"
		/>
	</label>
</template>

<script>
	import { defineComponent } from 'vue';
	import $ from 'jquery';
	import '../libs/jquery.alphanum';
	import toArray from 'lodash/toArray';

	export default defineComponent({
		name: 'RaInput',

		compatConfig: {
			COMPONENT_V_MODEL: true,
		},

		props: {
			name: {
				type: String,
				required: true,
			},
			text: {
				type: String,
				default: null,
			},
			value: {
				type: [String, Number],
				default: '',
			},
			required: Boolean,
			optional: Boolean,
			readonly: Boolean,
			type: {
				type: String,
				default: 'search',
			},
			filterType: {
				type: String,
				default: 'eq',
			},
			numericList: Boolean,
			numeric: Boolean,
			iconRight: Boolean,
			count: {
				type: String,
				default: null,
			},
			autocomplete: {
				type: String,
				default: 'off',
			},
			inputParent: {
				type: Function,
				default: null,
			},
			size: {
				type: String,
				default: 'regular',
			},
			numericConf: {
				type: Object,
				default: () => ({}),
			},
			maxLength: {
				type: Number,
				default: null,
			},
			isNoErrorText: {
				type: Boolean,
				default: false,
			},
			hint: {
				type: String,
				default: '',
			},
			isPasteSeparator: {
				type: Boolean,
				default: false,
			},
			error: {
				type: String,
				default: '',
			},
			isExtendable: {
				type: Boolean,
				default: false,
			},
			isChangeableFilter: {
				type: Boolean,
				default: false,
			},
		},
		emits: ['input', 'blur', 'focus'],

		data() {
			return {
				id: `inp_${this.name.toLowerCase()}`,
				inputType: this.type.toLowerCase(),
				inputValue: this.value,
				inputError: '',
				inputCount: this.count,
				isRequired: this.required,
				isPassword: this.type.toLowerCase() === 'password',
				filterTypeValue: this.filterType,
				isFocused: false,
			};
		},

		computed: {
			isEmpty() {
				return this.inputValue === null || this.inputValue === '';
			},

			charCount() {
				return toArray(String(this.inputValue)).length;
			},
		},

		watch: {
			value() {
				this.inputValue = this.value;
				if (this.inputParent) {
					this.inputParent(this.value, this.name);
				}
			},

			inputValue(val) {
				if (this.isPasteSeparator && typeof val === 'string') {
					this.inputValue = val
						.replace(/[\n\r\s]/g, ',')
						.replace(/[^\d,]/gi, '')
						.replace(/,+/g, ',');
				}
			},

			filterType() {
				this.filterTypeValue = this.filterType;
			},

			numericConf() {
				this.setInputSettings();
			},
		},

		mounted() {
			this.setInputSettings();
		},

		methods: {
			setInputSettings() {
				if (this.numericList) {
					$(this.$refs.input).off('.alphanum').alphanum({
						allowSpace: false,
						allowUpper: false,
						allowLower: false,
						allowNewline: false,
						allowNumeric: true,
						allowCaseless: false,
						allowLatin: false,
						allowOtherCharSets: false,
						allow: '1234567890,',
					});
				} else if (this.numeric) {
					$(this.$refs.input)
						.off('.alphanum')
						.numeric({ negative: false, decimal: false, ...this.numericConf });
				}
			},

			clear() {
				this.inputValue = '';
			},

			getValue() {
				return this.inputValue;
			},

			setValue(val) {
				this.inputValue = val;
			},

			getError() {
				return this.inputError;
			},

			setError(val) {
				this.inputError = val;
			},

			clearError() {
				this.inputError = '';
			},

			getFilterType() {
				return this.filterTypeValue;
			},

			setFilterType(val) {
				this.filterTypeValue = val;
			},

			insertAtCaret(text) {
				const tmpVal = this.getValue();
				const el = this.$refs.input;

				el.focus();

				const newVal = tmpVal.substring(0, el.selectionStart) + text + tmpVal.substring(el.selectionEnd, tmpVal.length);

				this.setValue(newVal);

				return newVal;
			},

			showPassword() {
				if (this.inputType === 'password') {
					this.inputType = 'text';
				} else {
					this.inputType = 'password';
				}

				this.$nextTick(() => {
					// Hack to set focus at the end of the string
					this.$refs.input.focus();
				});
			},

			onFocus(evt) {
				this.$emit('focus', evt.target.value);
				this.isFocused = true;
			},

			onBlur(evt) {
				this.$emit('blur', evt.target.value);
				this.isFocused = false;
			},

			changeFilterType() {
				switch (this.filterTypeValue) {
					case 'in':
						this.filterTypeValue = 'not_in';
						break;
					case 'not_in':
						this.filterTypeValue = 'in';
						break;
					case 'inset':
						this.filterTypeValue = 'not_inset';
						break;
					case 'not_inset':
						this.filterTypeValue = 'inset';
						break;
				}
			},
		},
	});
</script>
