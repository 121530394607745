<template>
	<div class="post-btn">
		<button class="header-button header-button--posts-list" @click="emit('click')" />
		<div v-if="props.showBadge" class="post-btn__badge" />
	</div>
</template>
<script lang="ts" setup>
	const props = withDefaults(
		defineProps<{
			showBadge?: boolean;
		}>(),
		{
			showBadge: false,
		}
	);

	const emit = defineEmits<{
		click: [];
	}>();
</script>
<style lang="scss" scoped>
	.post-btn {
		position: relative;

		&__badge {
			top: 0;
			right: 0;
			transform: translate(50%, -50%);
			position: absolute;
			transform-origin: 100%;

			width: 10px;
			height: 10px;
			background-color: #f87171;
			border-radius: 999px;
		}
	}
</style>
