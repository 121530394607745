<template>
	<nav class="nav nav--overflow text-body-3" :class="{ 'nav--collapsed': !isMenuVisible }">
		<router-link class="logo nav__logo" :class="{ 'logo--small': !isMenuVisible, 'nav__logo--open': !isMenuVisible }" to="/">
			Main Page
		</router-link>

		<ul class="nav__list menu">
			<li v-if="isResourceAllowed('statistics')">
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Statistics'"
					class="menu__item menu__item--statistics"
					to="/adm/statistics"
					@click="toggleMenuItem"
				>
					Statistics
				</router-link>
			</li>

			<li>
				<hr class="divider divider--small" />
			</li>

			<li v-if="isResourceAllowed('advertisers')">
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Advertisers'"
					class="menu__item menu__item--advertisers"
					to="/adm/advertisers"
					@click="toggleMenuItem"
				>
					Advertisers
				</router-link>
			</li>

			<li v-if="isResourceAllowed('campaigns')">
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Campaigns'"
					class="menu__item menu__item--campaigns"
					to="/adm/campaigns"
					:class="{ 'router-link-active': $route.path === '/adm/history' }"
					@click="toggleMenuItem"
				>
					Campaigns
				</router-link>
			</li>

			<li v-if="isResourceAllowed('pretargetings') || isResourceAllowed('adm_presets')">
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Presets'"
					class="menu__item menu__item--pretargetings"
					:to="
						isResourceAllowed('pretargetings') && isResourceAllowed('adm_presets')
							? '/adm/presets'
							: isResourceAllowed('adm_presets')
							? '/adm/presets'
							: '/adm/pretargetings'
					"
					@click="toggleMenuItem"
				>
					{{
						isResourceAllowed('pretargetings') && isResourceAllowed('adm_presets')
							? 'Presets'
							: isResourceAllowed('adm_presets')
							? 'Presets'
							: 'Pretargeting'
					}}
				</router-link>
			</li>

			<li v-if="isResourceAllowed('bidders')">
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Bidders'"
					class="menu__item menu__item--bidder"
					to="/adm/bidders"
					:class="{ 'router-link-active': $route.path === '/adm/protocols' }"
					@click="toggleMenuItem"
				>
					Bidders
				</router-link>
			</li>

			<li v-if="isResourceAllowed('moderation')">
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Moderate'"
					class="menu__item menu__item--moderate"
					to="/adm/moderates"
					@click="toggleMenuItem"
				>
					Moderate
				</router-link>
			</li>

			<li v-if="isResourceAllowed('invoices_adv')">
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Invoices'"
					class="menu__item menu__item--income"
					to="/adm/invoices"
					@click="toggleMenuItem"
				>
					Invoices
				</router-link>
			</li>

			<li>
				<hr class="divider divider--small" />
			</li>

			<li v-if="isResourceAllowed('publishers')">
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Publishers'"
					class="menu__item menu__item--publishers"
					to="/adm/publishers"
					@click="toggleMenuItem"
				>
					Publishers
				</router-link>
			</li>

			<li v-if="isResourceAllowed('sites')">
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Sites'"
					class="menu__item menu__item--sites"
					to="/adm/sites"
					@click="toggleMenuItem"
				>
					Sites
				</router-link>
			</li>

			<li v-if="isResourceAllowed('zones')">
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Zones'"
					class="menu__item menu__item--zones"
					to="/adm/zones"
					:class="{ 'router-link-active': $route.path === '/adm/zones' }"
					@click="toggleMenuItem"
				>
					Zones
				</router-link>
			</li>

			<li v-if="isResourceAllowed('payouts')">
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Payouts'"
					class="menu__item menu__item--finance"
					to="/adm/payouts"
					@click="toggleMenuItem"
				>
					Payouts
				</router-link>
			</li>

			<li>
				<hr class="divider divider--small" />
			</li>

			<li>
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Comments'"
					class="menu__item menu__item--comments"
					to="/adm/comments"
					@click="toggleMenuItem"
				>
					Comments
				</router-link>
			</li>

			<li>
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Creatives'"
					class="menu__item menu__item--creatives"
					to="/adm/creatives"
					@click="toggleMenuItem"
				>
					Creatives
				</router-link>
			</li>

			<li v-if="isResourceAllowed('finances')">
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Finance'"
					class="menu__item menu__item--cashdesk"
					to="/adm/finances"
					:class="{ 'router-link-active': $route.path === '/adm/finances-moderate' }"
					@click="toggleMenuItem"
				>
					Finance
				</router-link>
			</li>

			<li v-if="isResourceAllowed('users')">
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Users'"
					class="menu__item menu__item--users"
					to="/adm/users/users"
					:class="{ 'router-link-active': $route.path.includes('/adm/users/') }"
					@click="toggleMenuItem"
				>
					Users
				</router-link>
			</li>

			<template v-if="user.role_alias === 'dev'">
				<li>
					<router-link
						v-tooltip.bottom="isMenuVisible ? '' : 'Development'"
						class="menu__item menu__item--engine"
						to="/dev"
						@click="toggleMenuItem"
					>
						Development
					</router-link>
				</li>
			</template>

			<li v-if="isResourceAllowed('rates')">
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Rates'"
					class="menu__item menu__item--rates"
					to="/adm/rates"
					@click="toggleMenuItem"
				>
					Rates
				</router-link>
			</li>

			<li>
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Referrals'"
					to="/adm/referrals"
					class="menu__item menu__item--referrals"
					@click="toggleMenuItem"
				>
					Referrals
				</router-link>
			</li>

			<li>
				<hr class="divider divider--small" />
			</li>

			<li>
				<router-link
					v-tooltip.bottom="isMenuVisible ? '' : 'Marketing'"
					:to="canShowPromo ? '/adm/promo' : '/adm/posts'"
					class="menu__item menu__item--marketing"
					@click="toggleMenuItem"
				>
					Marketing
				</router-link>
			</li>
		</ul>
	</nav>
</template>

<script>
	import { defineComponent } from 'vue';
	import EventBus from '@/services/local/EventBusService';
	import User from '@/services/local/UserService';
	import { IS_NEW_API } from '@/helpers/const';
	import mixins from '../../../helpers/mixins.js';

	export default defineComponent({
		mixins: [mixins],

		data() {
			return {
				user: User.currentUser.user || {},
				isMenuVisible: true,
			};
		},

		computed: {
			canShowPromo() {
				// TODO: убрать IS_NEW_API после переезда
				return this.isResourceAllowed('promo') && IS_NEW_API;
			},
		},

		created() {
			User.on('change', this.changeAdmMenu.bind(this));
		},

		beforeUnmount() {
			User.off('change', this.changeAdmMenu.bind(this));

			EventBus.off('menuToggled', this.menuToggledHandler.bind(this));
		},

		mounted() {
			EventBus.on('menuToggled', this.menuToggledHandler.bind(this));

			localStorage.isMenuVisible === 'false' ? (this.isMenuVisible = false) : (this.isMenuVisible = true);
		},

		methods: {
			menuToggledHandler(val) {
				this.isMenuVisible = this.isMenuVisible !== val;
			},
			changeAdmMenu() {
				this.user = User.currentUser.user || {};
			},
			toggleMenuItem() {
				if (window.innerWidth >= 1024) {
					return;
				} else if (window.innerWidth >= 768) {
					this.isMenuVisible = false;
					localStorage.isMenuVisible = false;
				} else {
					this.isMenuVisible = true;
					localStorage.isMenuVisible = true;
				}
			},
		},
	});
</script>
