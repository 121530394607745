<template>
	<label class="text-field text-field--calendar" :for="id">
		<input
			:id="id"
			ref="container"
			v-model="dateValue"
			class="text-field__input"
			placeholder=" "
			type="text"
			:name="id"
			:class="{ 'text-field__input--non-empty': dateValue }"
			:disabled="disabled"
			:readonly="disabled"
			@blur="$emit('blur', $event)"
		/>
		<span class="text-field__label">{{ text }}</span>
		<input ref="hidden" v-model="inputValue" type="hidden" :name="name" data-type="bw" class="js-filter-field" />

		<span class="text-field__error-text text-caption">{{ error }}</span>
	</label>
</template>

<script>
	import $ from 'jquery';
	import moment from 'moment';

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	import daterangepicker from 'daterangepicker';

	export default {
		name: 'DatePicker',

		compatConfig: {
			COMPONENT_V_MODEL: true,
		},

		props: {
			name: {
				type: String,
				default: '',
			},
			error: {
				type: String,
				default: '',
			},
			text: {
				type: String,
				default: '',
			},
			periodPicker: {
				type: String,
				default: 'ts',
			},
			fieldName: {
				type: String,
				default: '',
			},
			addTime: Boolean,
			disabled: Boolean,
			value: {
				type: String,
				default: '',
			},
			maxDate: {
				type: Date,
				default: () => new Date(),
			},
			minDate: {
				type: Date,
				default: () => new Date(1970),
			},
		},
		emits: ['apply', 'input', 'cancel', 'blur'],

		data() {
			return {
				dateValue: '',
				id: `periodpicker_${Date.now()}_${Math.round(Math.random() * 99999)}`,
				inputValue: this.value || '',
			};
		},

		computed: {
			isEmpty() {
				return !this.dateValue;
			},
		},
		watch: {
			value() {
				this.setDateValue();
			},
		},
		mounted() {
			const params = {
				autoApply: false,
				autoUpdateInput: false,
				locale: {
					format: 'YYYY-MM-DD',
					cancelLabel: 'Clear',
					firstDay: 1,
				},
				linkedCalendars: false,
				showCustomRangeLabel: false,
				alwaysShowCalendars: true,
				maxDate: this.maxDate,
				minDate: this.minDate,
				singleDatePicker: true,
			};

			$(this.$refs.container).daterangepicker(params);

			if (this.inputValue) {
				this.setDateValue();
			}

			$(this.$refs.container).on('cancel.daterangepicker', () => {
				this.inputValue = '';
				this.dateValue = '';

				this.$emit('cancel');
			});

			$(this.$refs.container).on('apply.daterangepicker', (ev, picker) => {
				this.dateValue = `${picker.startDate.format('YYYY-MM-DD')}`;

				if (this.addTime) {
					this.inputValue = `${this.fieldName};${picker.startDate.format('YYYY-MM-DD 00:00:00')}`;
				} else {
					this.inputValue = `${this.fieldName};${picker.startDate.format('YYYY-MM-DD')}`;
				}

				this.$emit('apply', this.dateValue);
				this.$emit('input', this.dateValue);
			});
		},

		beforeUnmount() {
			$(this.$refs.container).off('.daterangepicker');
			$(this.$refs.container).data('daterangepicker').remove();
		},

		methods: {
			setDateValue() {
				const resultFormat = this.addTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';
				const startDate = moment(this.value, resultFormat).format('YYYY-MM-DD');

				$(this.$refs.container).data('daterangepicker').setStartDate(startDate);
				$(this.$refs.container).data('daterangepicker').setEndDate(startDate);

				this.dateValue = `${startDate}`;
			},
			clear() {
				this.inputValue = '';
				this.dateValue = '';

				const now = new Date();

				$(this.$refs.container).data('daterangepicker').setStartDate(now);
				$(this.$refs.container).data('daterangepicker').setEndDate(now);
			},

			getValue() {
				return this.disabled ? '' : this.inputValue;
			},

			setValue(val) {
				const resultFormat = this.addTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';
				const startDate = val ? moment(val, resultFormat).format('YYYY-MM-DD') : null;

				this.inputValue = val;
				this.dateValue = startDate;

				$(this.$refs.container).data('daterangepicker').setStartDate(startDate);
				$(this.$refs.container).data('daterangepicker').setEndDate(startDate);
			},

			getFilterType() {
				return this.periodPicker;
			},
		},
	};
</script>
