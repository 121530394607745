<template>
	<div class="post-list">
		<div v-for="post in props.posts" :key="post.post_id" class="post-list__item">
			<post v-bind="post" @click="emit('openPost', post)" />
		</div>
	</div>
</template>

<script lang="ts" setup>
	import type { IClientPost } from './types';
	import Post from './Post.vue';

	const props = withDefaults(
		defineProps<{
			posts?: Array<IClientPost>;
			isLoading?: boolean;
		}>(),
		{
			posts: () => [],
		}
	);

	const emit = defineEmits<{
		openPost: [data: IClientPost];
	}>();
</script>

<style lang="scss" scoped>
	.post-list {
		margin: 0 -24px -24px -24px;
		padding: 0 24px;
		overflow: auto;

		&__item {
			padding: 24px 0;

			&:not(:first-child) {
				border-top: 1px solid var(--bg-border);
			}
		}
	}
</style>
