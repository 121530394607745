<template>
	<div class="header__personal">
		<p class="text-body-3 server-time">
			<span>{{ currentTime }}</span>
			(UTC)
			<span v-if="GIT_BRANCH">&nbsp;[{{ GIT_BRANCH }}]</span>
		</p>

		<router-link v-tooltip.bottom="'Top up'" to="/adv/finances" class="balance text-body-3">
			Balance: {{ formatUSD(balance) }}
		</router-link>
	</div>
</template>

<script>
	import { useCurrentTime } from '@/composables/useCurrentTime';
	import { GIT_BRANCH } from '@/helpers/const';
	import mixins from '../../../helpers/mixins.js';
	import User from '../../../services/local/UserService';

	const { currentTime } = useCurrentTime();

	export default {
		mixins: [mixins],

		data() {
			return {
				account: { ...User.currentUser.account } || {},
				currentTime,
				GIT_BRANCH,
			};
		},

		computed: {
			balance() {
				return this.account.balance_current.toFixed(2);
			},
		},

		created() {
			User.on(['change', 'change_balance'], this.changeAdvTop);
		},

		beforeUnmount() {
			User.off(['change', 'change_balance'], this.changeAdvTop);
		},

		methods: {
			changeAdvTop() {
				this.account = { ...User.currentUser.account } || {};
			},
		},
	};
</script>
