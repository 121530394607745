<template>
	<div class="card-group">
		<slot />
	</div>
</template>

<script lang="ts">
	import { defineComponent } from 'vue';

	export default defineComponent({
		name: 'CardGroup',
	});
</script>
