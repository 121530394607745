import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "base-modal__header" }
const _hoisted_2 = { class: "base-modal__title" }
const _hoisted_3 = {
  key: 1,
  class: "base-modal__title--default"
}
const _hoisted_4 = { class: "base-modal__content" }
const _hoisted_5 = {
  key: 0,
  class: "base-modal__footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["VueFinalModal"], {
    "data-class": "base-modal-wrapper",
    "overlay-class": "base-modal-overlay",
    "content-class": "base-modal",
    "data-model-position": $setup.position,
    "modal-id": $setup.modalId,
    "content-style": $setup.cssStyle,
    "content-transition": $setup.contentTransition,
    onClosed: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('closed'))),
    onOpened: _cache[1] || (_cache[1] = ($event: any) => ($setup.emit('opened')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.$slots.title)
            ? _renderSlot(_ctx.$slots, "title", {
                key: 0,
                title: $props.title
              })
            : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($props.title), 1 /* TEXT */))
        ]),
        ($props.closeable)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "header-button base-modal__cross",
              onClick: $setup.closeHandler
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default", { closeHandler: $setup.closeHandler })
      ]),
      (_ctx.$slots.footer)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "footer", { closeHandler: $setup.closeHandler })
          ]))
        : _createCommentVNode("v-if", true)
    ], undefined, true),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["data-model-position", "modal-id", "content-style", "content-transition"]))
}