import UserService from '@/services/local/UserService';
import type { Guard } from '@/router/types';

export const authGuard: Guard = (to, from, next) => {
	const allowedUrls = ['/privacy', '/unsubscribe', '/referrals', '/ref'];

	if (to.path.indexOf('/auth/') === 0 && UserService.isLoggedIn()) {
		return next({ path: '/' });
	}

	if (to.path.indexOf('/auth/') === 0 || to.path.indexOf('/2fa/') === 0 || allowedUrls.indexOf(to.path) > -1) {
		return next();
	}

	if (['/logout'].indexOf(to.path) > -1) {
		return next();
	}

	if (!UserService.isLoggedIn()) {
		next({ path: '/auth/login' });
	} else {
		if (to.path.indexOf(`/docs`) === 0) {
			return next();
		}

		if (to.path.indexOf(`/${UserService.currentUser.user?.role_type}`) === 0) {
			return next();
		}

		if (
			UserService.currentUser.user?.role_type === 'adm' &&
			(to.path.indexOf(`/adm`) === 0 || to.path.indexOf(`/dev`) === 0)
		) {
			return next();
		}

		next({ path: UserService.getHomepage() });
	}
};

export const isNotRTBPub: Guard = () => {
	if (UserService.currentUser.account?.account_integration !== 'rtb') {
		return true;
	} else {
		return { name: '404' };
	}
};

export const isFeatureEnabled: (feature: string) => Guard = (feature) => () => {
	if (UserService.isFeatureEnabled(feature)) {
		return true;
	} else {
		return { name: '404' };
	}
};
