import { ref } from 'vue';

const menuStore = ref(false);

export default () => {
	const setMenu = (value: boolean) => {
		menuStore.value = value;
	};

	return {
		menuStore,
		setMenu,
	};
};
