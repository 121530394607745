import { createApp, configureCompat } from 'vue';
import router from './router';
import { setupPlugin } from './setupApp';
import App from './pages/App.vue';

configureCompat({
	MODE: 3,
	CUSTOM_DIR: true,
	INSTANCE_CHILDREN: true,
	INSTANCE_EVENT_EMITTER: true,
	ATTR_FALSE_VALUE: true,
	INSTANCE_DELETE: true,
	INSTANCE_ATTRS_CLASS_STYLE: true,
});

if (!process.env.VUE_APP_SERVER_URL) {
	throw new Error('VUE_APP_SERVER_URL');
}

router().then((router) => {
	window.router = router;

	createApp(App).use(setupPlugin, { router }).mount('#app');
});
